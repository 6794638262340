html#iubenda_policy,#iubenda_policy body {
    margin: 0;
    padding: 0
}

html#iubenda_policy {
    overflow-y: scroll;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

#iubenda_policy h1,#iubenda_policy h2,#iubenda_policy h3,#iubenda_policy h4,#iubenda_policy h5,#iubenda_policy h6,#iubenda_policy p,#iubenda_policy blockquote,#iubenda_policy pre,#iubenda_policy a,#iubenda_policy abbr,#iubenda_policy acronym,#iubenda_policy address,#iubenda_policy cite,#iubenda_policy code,#iubenda_policy del,#iubenda_policy dfn,#iubenda_policy em,#iubenda_policy img,#iubenda_policy q,#iubenda_policy s,#iubenda_policy samp,#iubenda_policy small,#iubenda_policy strike,#iubenda_policy strong,#iubenda_policy sub,#iubenda_policy sup,#iubenda_policy tt,#iubenda_policy var,#iubenda_policy dd,#iubenda_policy dl,#iubenda_policy dt,#iubenda_policy li,#iubenda_policy ol,#iubenda_policy ul,#iubenda_policy fieldset,#iubenda_policy form,#iubenda_policy label,#iubenda_policy legend,#iubenda_policy button,#iubenda_policy table,#iubenda_policy caption,#iubenda_policy tbody,#iubenda_policy tfoot,#iubenda_policy thead,#iubenda_policy tr,#iubenda_policy th,#iubenda_policy td {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    font-style: normal;
    font-size: 100%;
    line-height: 1;
    font-family: inherit
}

#iubenda_policy table {
    border-collapse: collapse;
    border-spacing: 0
}

#iubenda_policy ol,#iubenda_policy ul {
    list-style: none
}

#iubenda_policy q:before,#iubenda_policy q:after,#iubenda_policy blockquote:before,#iubenda_policy blockquote:after {
    content: ""
}

#iubenda_policy a:focus {
    outline: thin dotted
}

#iubenda_policy a:hover,#iubenda_policy a:active {
    outline: 0
}

#iubenda_policy article,#iubenda_policy aside,#iubenda_policy details,#iubenda_policy figcaption,#iubenda_policy figure,#iubenda_policy footer,#iubenda_policy header,#iubenda_policy hgroup,#iubenda_policy nav,#iubenda_policy section {
    display: block
}

#iubenda_policy audio,#iubenda_policy canvas,#iubenda_policy video {
    display: inline-block;
    *display: inline;
    *zoom:1}

#iubenda_policy audio:not([controls]) {
    display: none
}

#iubenda_policy sub,#iubenda_policy sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

#iubenda_policy sup {
    top: -0.5em
}

#iubenda_policy sub {
    bottom: -0.25em
}

#iubenda_policy img {
    border: 0;
    -ms-interpolation-mode: bicubic
}

#iubenda_policy button,#iubenda_policy input,#iubenda_policy select,#iubenda_policy textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle
}

#iubenda_policy button,#iubenda_policy input {
    line-height: normal;
    *overflow: visible
}

#iubenda_policy button::-moz-focus-inner,#iubenda_policy input::-moz-focus-inner {
    border: 0;
    padding: 0
}

#iubenda_policy button,#iubenda_policy input[type="button"],#iubenda_policy input[type="reset"],#iubenda_policy input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
    appearance: button;
}

#iubenda_policy input[type="search"] {
    -webkit-appearance: textfield;
    appearance: textfield;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

#iubenda_policy input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

#iubenda_policy textarea {
    overflow: auto;
    vertical-align: top
}

html#iubenda_policy {
    -webkit-font-smoothing: antialiased
}

#iubenda_policy p {
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: 9px
}

#iubenda_policy p small {
    font-size: 11px;
    color: #bfbfbf
}

#iubenda_policy h1,#iubenda_policy h2,#iubenda_policy h3,#iubenda_policy h4,#iubenda_policy h5,#iubenda_policy h6 {
    font-weight: bold;
    color: #505762
}

#iubenda_policy h1 {
    margin-bottom: 18px;
    font-size: 30px;
    line-height: 2
}

#iubenda_policy h1 small {
    font-size: 18px
}

#iubenda_policy h2 {
    font-size: 24px;
    margin-bottom: 18px;
    line-height: 1.5
}

#iubenda_policy h2 small {
    font-size: 14px
}

#iubenda_policy h3,#iubenda_policy h4,#iubenda_policy h5,#iubenda_policy h6 {
    margin-bottom: 9px
}

#iubenda_policy h3 {
    font-size: 18px
}

#iubenda_policy h3 small {
    font-size: 14px
}

#iubenda_policy h4 {
    font-size: 16px
}

#iubenda_policy h4 small {
    font-weight: bold;
    font-size: 13px
}

#iubenda_policy h5 {
    font-size: 13px;
    padding-top: 19px
}

#iubenda_policy h6 {
    font-size: 13px;
    color: #bfbfbf;
    text-transform: uppercase
}

#iubenda_policy ul ul,#iubenda_policy ul ol,#iubenda_policy ol ol,#iubenda_policy ol ul {
    margin: 0
}

#iubenda_policy ul.styled,#iubenda_policy ul {
    list-style: disc;
    padding-top: 5px
}

#iubenda_policy ul.styled li,#iubenda_policy ul li {
    list-style: disc;
    line-height: 19px;
    font-size: 13px;
    margin-left: 30px;
    margin-top: 2px
}

#iubenda_policy ol {
    list-style: decimal
}

#iubenda_policy ul.unstyled {
    list-style: none;
    margin-left: 0
}

#iubenda_policy dl {
    margin-bottom: 18px
}

#iubenda_policy dl dt,#iubenda_policy dl dd {
    line-height: 18px
}

#iubenda_policy dl dt {
    font-weight: bold
}

#iubenda_policy dl dd {
    margin-left: 9px
}

#iubenda_policy hr {
    margin: 0 0 19px;
    border: 0;
    border-bottom: 1px solid #eee
}

#iubenda_policy strong {
    font-style: inherit;
    font-weight: bold
}

#iubenda_policy em {
    font-style: italic;
    font-weight: inherit;
    line-height: inherit
}

#iubenda_policy .muted {
    color: #bfbfbf
}

#iubenda_policy blockquote {
    margin-bottom: 18px;
    border-left: 5px solid #eee;
    padding-left: 15px
}

#iubenda_policy blockquote p {
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    margin-bottom: 0
}

#iubenda_policy blockquote small {
    display: block;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: #bfbfbf
}

#iubenda_policy blockquote small:before {
    content: '\2014 \00A0'
}

#iubenda_policy address {
    display: block;
    line-height: 18px;
    margin-bottom: 18px
}

#iubenda_policy code,#iubenda_policy pre {
    padding: 0 3px 2px;
    font-family: Monaco, Andale Mono, Courier New, monospace;
    font-size: 12px;
    border-radius: 3px
}

#iubenda_policy code {
    background-color: #fee9cc;
    color: rgba(0,0,0,0.75);
    padding: 1px 3px
}

#iubenda_policy pre {
    background-color: #f5f5f5;
    display: block;
    padding: 17px;
    margin: 0 0 18px;
    line-height: 18px;
    font-size: 12px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 3px;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word
}

#iubenda_policy .breadcrumbs {
    padding: 0 0 10px 0;
    margin-bottom: 30px;
    border-bottom: 1px solid #F6F6F6;
    width: 100%
}

#iubenda_policy .breadcrumbs>li {
    float: left;
    filter: alpha(opacity= "$opacity");
    -khtml-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5
}

#iubenda_policy .breadcrumbs>li:not(:last-child):after {
    color: #333B43;
    padding: 0 10px;
    content: "\203a"
}

#iubenda_policy .breadcrumbs+.pills,#iubenda_policy .breadcrumbs+.sec_tabs {
    margin-top: -15px
}

#iubenda_policy .table {
    display: table;
    border-collapse: collapse;
    padding: 0 !important;
    margin: 0
}

#iubenda_policy .cust_row {
    display: table-row;
    margin: 0
}

#iubenda_policy .column {
    display: table-cell;
    vertical-align: top;
    padding: 30px
}

#iubenda_policy .box_primary {
    border: 1px solid #C0C1C1;
    border-bottom-color: #A8AAAB;
    -webkit-box-shadow: 0 1px 0 #EBEBEC;
    box-shadow: 0 1px 0 #EBEBEC;
    -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.1);
    box-shadow: 0 1px 0 rgba(0,0,0,0.1);
    background: #FFF
}

#iubenda_policy .box_content {
    border-radius: 4px;
    padding: 30px
}

#iubenda_policy .box_content .iub_content {
    padding: 30px
}

#iubenda_policy .box_content .iub_content>hr {
    width: 686px;
    margin-left: -30px;
    margin-right: -30px
}

#iubenda_policy .box_content .aside {
    width: 191px;
    padding: 30px
}

#iubenda_policy .box_content .aside.aside-right {
    border-left: 1px solid #DFDFDF
}

#iubenda_policy .table>.box_content {
    padding: 0
}

#iubenda_policy .box_10 {
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 15px
}

#iubenda_policy .box_10>h4 {
    margin-bottom: 0;
    font-size: 13px
}

#iubenda_policy .box_10>.w_icon,#iubenda_policy .box_10>.w_icon.expand-click,#iubenda_policy .box_10.expand>.w_icon,#iubenda_policy .box_10.expand>.w_icon.expand-click {
    padding-left: 45px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 10px;
    background-position-y: 7px;
    background-position: 10px 7px
}

#iubenda_policy .box_10>.w_icon_16,#iubenda_policy .box_10>.w_icon_16.expand-click,#iubenda_policy .box_10.expand>.w_icon_16,#iubenda_policy .box_10.expand>.w_icon_16.expand-click {
    padding-left: 40px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 11px;
    background-position-y: 11px;
    background-position: 11px 11px
}

#iubenda_policy .box_10>.w_icon_24,#iubenda_policy .box_10>.w_icon_24.expand-click,#iubenda_policy .box_10.expand>.w_icon_24,#iubenda_policy .box_10.expand>.w_icon_24.expand-click {
    padding-left: 45px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 10px;
    background-position-y: 10px;
    background-position: 10px 10px
}

#iubenda_policy .box_5 {
    padding: 5px;
    border-radius: 3px;
    font-size: 11px;
    margin-bottom: 15px
}

#iubenda_policy .box_5 hr {
    padding-top: 5px;
    margin: 0 -5px 5px -5px;
    border: 0;
    border-bottom: 1px solid #AC3737
}

#iubenda_policy .box_5.w_icon_16 {
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position-x: 8px;
    background-position-y: 6px;
    background-position: 8px 6px
}

#iubenda_policy .box_5.w_icon_16 hr {
    width: 100%;
    padding-left: 30px;
    padding-right: 5px;
    margin-left: -30px;
    margin-right: -5px
}

#iubenda_policy .box_thumb {
    background: #FFF;
    -webkit-box-shadow: 0 0 1px #A3A3A3, 0 1px 1px #A3A3A3;
    box-shadow: 0 0 1px #A3A3A3, 0 1px 1px #A3A3A3;
    padding: 6px
}

#iubenda_policy footer {
    margin-top: 17px;
    padding-top: 17px;
    border-top: 1px solid #eee
}

#iubenda_policy hr {
    padding-top: 15px;
    margin: 0 0 15px 0
}

#iubenda_policy hr.primary {
    border: 0;
    border-bottom: 1px solid #DFDFDF;
    -webkit-box-shadow: 0 1px 0 #F7F7F7;
    box-shadow: 0 1px 0 #F7F7F7
}

#iubenda_policy .btn {
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    background-color: rgba(0,0,0,0.08);
    padding: 0 20px;
    line-height: 38px;
    color: #54616B;
    font-size: 13px;
    border: 0;
    border-radius: 4rem;
    border-collapse: separate;
    -webkit-transition: 0.1s linear all;
    transition: 0.1s linear all
}

#iubenda_policy .btn:hover {
    background-position: 0 -15px;
    text-decoration: none
}

#iubenda_policy .btn:focus {
    outline: 1px dotted #666
}

#iubenda_policy .btn:active {
    -webkit-box-shadow: inset 0 2px 4px rgba(0,0,0,0.25),0 1px 2px rgba(0,0,0,0.05);
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.25),0 1px 2px rgba(0,0,0,0.05)
}

#iubenda_policy .btn.disabled {
    cursor: default;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    filter: alpha(opacity= "$opacity");
    -khtml-opacity: 0.65;
    -moz-opacity: 0.65;
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none
}

#iubenda_policy .btn[disabled] {
    cursor: default;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    filter: alpha(opacity= "$opacity");
    -khtml-opacity: 0.65;
    -moz-opacity: 0.65;
    opacity: 0.65;
    -webkit-box-shadow: none;
    box-shadow: none
}

#iubenda_policy .btn.large {
    font-size: 16px;
    line-height: normal;
    padding: 0 14px;
    border-radius: 6px
}

#iubenda_policy .btn.mid_large {
    padding: 0 10px;
    line-height: 32px;
    font-size: 13px
}

#iubenda_policy .btn.mid {
    padding: 0 10px;
    line-height: 28px;
    font-size: 11px
}

#iubenda_policy .btn.small {
    padding: 0 8px;
    line-height: 18px;
    font-size: 11px
}

#iubenda_policy :root .alert-message,#iubenda_policy :root .btn {
    border-radius: 0 \0
}

#iubenda_policy button.btn::-moz-focus-inner,#iubenda_policy input.btn[type=submit]::-moz-focus-inner {
    padding: 0;
    border: 0
}

#iubenda_policy .circle {
    font-size: 11px;
    line-height: 18px;
    width: 18px;
    padding: 0;
    text-align: center;
    border-radius: 11px
}

#iubenda_policy .circle.small {
    width: 14px;
    line-height: 14px;
    border-radius: 9px;
    padding: 0
}

#iubenda_policy .blue,#iubenda_policy .btn.primary {
    color: #fff;
    background-color: #0073CE
}

#iubenda_policy .yellow {
    color: #6D693D;
    background-color: #FFD24D
}

#iubenda_policy .red {
    color: #FFF;
    background-color: #FF5D4D
}

#iubenda_policy .red a,#iubenda_policy .red a:hover:not(.btn) {
    color: #FFF
}

#iubenda_policy .red a {
    border-bottom-color: rgba(247,247,247,0.3)
}

#iubenda_policy .red a:hover {
    border-bottom-color: rgba(247,247,247,0.6)
}

#iubenda_policy .green {
    color: #4D6C47;
    background-color: #F1FFD5
}

#iubenda_policy .iubgreen {
    color: #01281B;
    background-color: #1CC691
}

#iubenda_policy .azure {
    color: #364048;
    background-color: #D2ECFE
}

#iubenda_policy .white {
    color: #54616B;
    background-color: #F8F8F8
}

#iubenda_policy .black {
    color: #FFF;
    background-color: #333333
}

#iubenda_policy .trasp {
    color: #333B43;
    background-color: #FFFFFF
}

#iubenda_policy .alert-message {
    position: relative;
    padding: 7px 15px;
    margin-bottom: 18px;
    color: #404040;
    background-color: #eedc94;
    background-repeat: repeat-x;
    background-image: -khtml-gradient(linear, left top, left bottom, from(#fceec1), to(#eedc94));
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fceec1), to(#eedc94));
    background-image: linear-gradient(to bottom, #fceec1, #eedc94);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$startColor', endColorstr='$endColor', GradientType=0);
    text-shadow: 0 -1px 0 rgba(0,0,0,0.25);
    border-color: #eedc94 #eedc94 #e4c652;
    border-color: rgba(0,0,0,0.1) rgba(0,0,0,0.1) fadein(rgba(0,0,0,0.1), 15%);
    text-shadow: 0 1px 0 rgba(255,255,255,0.5);
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.25);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.25)
}

#iubenda_policy .alert-message .close {
    *margin-top: 3px
}

#iubenda_policy .alert-message h5 {
    line-height: 18px
}

#iubenda_policy .alert-message p {
    margin-bottom: 0
}

#iubenda_policy .alert-message div {
    margin-top: 5px;
    margin-bottom: 2px;
    line-height: 28px
}

#iubenda_policy .alert-message .btn {
    -webkit-box-shadow: 0 1px 0 rgba(255,255,255,0.25);
    box-shadow: 0 1px 0 rgba(255,255,255,0.25)
}

#iubenda_policy .alert-message.block-message {
    background-image: none;
    background-color: #fdf5d9;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
    padding: 14px;
    border-color: #fceec1;
    -webkit-box-shadow: none;
    box-shadow: none
}

#iubenda_policy .alert-message.block-message ul,#iubenda_policy .alert-message.block-message p {
    margin-right: 30px
}

#iubenda_policy .alert-message.block-message ul {
    margin-bottom: 0
}

#iubenda_policy .alert-message.block-message li {
    color: #404040
}

#iubenda_policy .alert-message.block-message .alert-actions {
    margin-top: 5px
}

#iubenda_policy .alert-message.block-message.error,#iubenda_policy .alert-message.block-message.success,#iubenda_policy .alert-message.block-message.info {
    color: #404040;
    text-shadow: 0 1px 0 rgba(255,255,255,0.5)
}

#iubenda_policy .alert-message.block-message.error {
    background-color: #fddfde;
    border-color: #fbc7c6
}

#iubenda_policy .alert-message.block-message.success {
    background-color: #d1eed1;
    border-color: #bfe7bf
}

#iubenda_policy .alert-message.block-message.info {
    background-color: #ddf4fb;
    border-color: #c6edf9
}

#iubenda_policy .fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    opacity: 0
}

#iubenda_policy .fade.in {
    opacity: 1
}

#iubenda_policy .expand-click {
    cursor: pointer;
    position: relative
}

#iubenda_policy .box_10.expand .expand-click {
    margin: -10px;
    padding: 12px 25px 13px 10px
}

#iubenda_policy .box_10.expand .expand-content {
    margin-top: 10px
}

#iubenda_policy .box_10.expand .expand-content>*:first-child {
    margin-top: 0;
    padding-top: 0
}

#iubenda_policy .expand.expanded .expand-click:after,#iubenda_policy .box_10.expand.expanded .expand-click:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 19px;
    border: 5px;
    border-color: transparent;
    border-style: solid;
    border-top-color: #333B43
}

#iubenda_policy .expand .expand-click,#iubenda_policy .expand.expanded .expand-click,#iubenda_policy .box_10.expand .expand-click,#iubenda_policy .box_10.expand.expanded .expand-click {
    border-bottom: 1px dotted #DDD;
    margin-bottom: 10px;
    -webkit-transition: 0.2s linear all;
    transition: 0.2s linear all
}

#iubenda_policy .expand.collapsed .expand-click {
    border-bottom: 0;
    margin-bottom: -10px
}

#iubenda_policy .expand.collapsed .expand-click:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 17px;
    border: 5px;
    border-color: transparent;
    border-style: solid;
    border-right-color: #333B43
}

#iubenda_policy .all-collapsed .expand .expand-click:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 17px;
    border: 5px;
    border-color: transparent;
    border-style: solid;
    border-right-color: #333B43
}

#iubenda_policy .all-collapsed .expand .expand-click {
    border-bottom: 0;
    margin-bottom: -10px
}

#iubenda_policy .all-collapsed .expand-content {
    display: none
}

html#iubenda_policy,#iubenda_policy body {
    background-color: #FFF
}

#iubenda_policy {
    font-family: "Helvetica Neue", Helvetica, Arial, FreeSans, sans-serif;
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    color: #505762
}

#iubenda_policy body {
    margin: 0
}

#iubenda_policy .iub_container-fluid {
    position: relative;
    min-width: 940px;
    padding-left: 20px;
    padding-right: 20px;
    zoom:1}

#iubenda_policy .iub_container-fluid:before,#iubenda_policy .iub_container-fluid:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy .iub_container-fluid:after {
    clear: both
}

#iubenda_policy .iub_container-fluid>.sidebar {
    float: left;
    width: 220px
}

#iubenda_policy .iub_container-fluid>.iub_content {
    margin-left: 240px
}

#iubenda_policy a {
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid #F6F6F6;
    color: #333B43
}

#iubenda_policy a.unstyled {
    border-bottom: 0
}

#iubenda_policy a:hover:not(.btn) {
    color: #121518;
    border-bottom-color: #D6D6D6;
    -webkit-transition: 0.1s linear all;
    transition: 0.1s linear all
}

#iubenda_policy a.no_border,#iubenda_policy a.no_border:hover {
    border-bottom-width: 0
}

#iubenda_policy .pull-right {
    float: right
}

#iubenda_policy .pull-left {
    float: left
}

#iubenda_policy .hide {
    display: none
}

#iubenda_policy .show {
    display: block
}

#iubenda_policy .link_on_dark a {
    border-bottom-color: rgba(247,247,247,0.3)
}

#iubenda_policy .link_on_dark a:hover {
    border-bottom-color: rgba(247,247,247,0.6)
}



#iubenda_policy .iub_base_container {
    border-radius: 3px;
    background: #fff;
    color: #595858;
    position: relative;
    margin: 0px important!
}


#iubenda_policy p {
    line-height: 19px;
    margin: 0;
    padding-top: 11px
}

#iubenda_policy address {
    margin: 0;
    line-height: inherit;
    display: inline
}

#iubenda_policy a {
    font-weight: normal;
    border-bottom: 1px solid #F0F0F0
}

#iubenda_policy .iub_content {
    position: relative;
    padding: 25px 30px;
    margin: 0 auto;
    border-radius: 3px 3px 0 0
}

#iubenda_policy #wbars {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden
}

#iubenda_policy #wbars .horizontal {
    display: none
}

#iubenda_policy .iub_header {
    border-bottom: 1px dotted #DFDFDF;
    padding-bottom: 25px;
    position: relative
}

#iubenda_policy .iub_header p {
    margin: 0;
    padding: 0
}

#iubenda_policy .iub_header img {
    display: block;
    position: absolute;
    top: 5px;
    right: 0
}

#iubenda_policy h1,#iubenda_policy h2,#iubenda_policy h3 {
    color: #262626;
    margin: 0
}

#iubenda_policy h1+p,#iubenda_policy h2+p,#iubenda_policy h3+p {
    padding-top: 5px
}

#iubenda_policy h1 {
    font-size: 19px;
    font-weight: normal;
    line-height: 23px;
    margin-bottom: 5px
}

#iubenda_policy h2 {
    font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    padding-top: 21px
}

#iubenda_policy h3 {
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    padding-top: 24px
}

#iubenda_policy h3+p {
    padding-top: 0
}

#iubenda_policy .iconed ul li h3 {
    padding-top: 10px;
    color: #615e5e
}

#iubenda_policy h4 {
    font-size: 13px;
    font-weight: bold;
    padding-top: 19px;
    margin-bottom: 0
}

#iubenda_policy h4:first-child {
    padding-top: 0
}

#iubenda_policy h6 {
    color: #262626;
    text-transform: none;
    padding-top: 19px
}

#iubenda_policy ul.for_boxes,#iubenda_policy ul.for_boxes>li,#iubenda_policy ul.unstyled,#iubenda_policy ul.unstyled>li {
    list-style: none;
    padding: 0;
    margin: 0
}

#iubenda_policy ul.for_boxes {
    zoom:1}

#iubenda_policy ul.for_boxes:before,#iubenda_policy ul.for_boxes:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy ul.for_boxes:after {
    clear: both
}

#iubenda_policy .half_col {
    float: left;
    width: 50%;
    zoom:1}

#iubenda_policy .half_col:before,#iubenda_policy .half_col:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy .half_col:after {
    clear: both
}

#iubenda_policy .half_col:nth-child(2n+1)>* {
    margin-right: 15px
}

#iubenda_policy .half_col:nth-child(2n)>* {
    margin-left: 15px
}

#iubenda_policy .half_col+.one_line_col,#iubenda_policy .half_col+.iub_footer {
    border-top: 1px dotted #DFDFDF
}

#iubenda_policy .one_line_col {
    zoom:1;float: left;
    width: 100%;
    border-bottom: 1px dotted #DFDFDF
}

#iubenda_policy .one_line_col:before,#iubenda_policy .one_line_col:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy .one_line_col:after {
    clear: both
}

#iubenda_policy .one_line_col>ul.for_boxes>li {
    float: left;
    width: 50%
}

#iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1) {
    clear: left
}

#iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div {
    margin-right: 15px
}

#iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n) {
    clear: right
}

#iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n)>div {
    margin-left: 15px
}

#iubenda_policy .one_line_col.wide {
    width: 100%
}

#iubenda_policy .one_line_col.wide>ul.for_boxes>li {
    clear: both;
    width: 100%
}

#iubenda_policy .one_line_col.wide>ul.for_boxes>li:nth-child(2n+1)>div {
    margin-right: 0
}

#iubenda_policy .one_line_col.wide>ul.for_boxes>li:nth-child(2n)>div {
    margin-left: 0
}

#iubenda_policy ul.normal_list {
    list-style: disc;
    display: block;
    padding-top: 11px
}

#iubenda_policy ul.normal_list li {
    list-style: disc;
    float: none;
    line-height: 19px;
    margin: 5px 25px
}

#iubenda_policy .simple_pp>ul>li {
    padding-bottom: 21px
}

#iubenda_policy .simple_pp>ul>li>ul .iconed {
    padding-left: 40px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 2px;
    background-position-y: 26px;
    background-position: 2px 26px
}

#iubenda_policy .simple_pp .for_boxes>.one_line_col>ul.for_boxes {
    margin-top: 0
}

#iubenda_policy .legal_pp .one_line_col {
    float: none;
    border-top: 0;
    padding-bottom: 21px
}

#iubenda_policy .legal_pp .one_line_col>ul.for_boxes {
    margin-top: 21px
}

#iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li:nth-child(2n+1) {
    clear: left;
    float: left
}

#iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li:nth-child(2n) {
    float: right;
    clear: right
}

#iubenda_policy .legal_pp .definitions {
    margin-top: 21px
}

#iubenda_policy .legal_pp .definitions .expand-click.w_icon_24 {
    margin-top: -11px;
    padding: 14px 10px 12px 45px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position-x: 5px;
    background-position-y: 0;
    background-position: 5px 0
}

#iubenda_policy .legal_pp .definitions .expand-content {
    padding-left: 5px;
    padding-right: 5px
}

#iubenda_policy .wrap p {
    display: inline-block
}

#iubenda_policy .iub_footer {
    clear: both;
    position: relative;
    font-size: 11px
}

#iubenda_policy .iub_footer p {
    font-size: 11px;
    padding: 0
}

#iubenda_policy .iub_content .iub_footer {
    padding: 24px 0
}

#iubenda_policy .iub_content .iub_footer p {
    margin: 10px 0;
    clear: both
}

#iubenda_policy .iub_content .iub_footer .show_comp_link {
    display: block;
    float: right
}

#iubenda_policy .iub_container>.iub_footer {
    min-height: 21px;
    background-color: #F6F6F6;
    color: #717171;
    padding: 30px;
    -webkit-box-shadow: 0 -1px 6px #CFCFCF;
    box-shadow: 0 -1px 6px #CFCFCF;
    border-radius: 0 0 3px 3px
}

#iubenda_policy .iub_container>.iub_footer>.btn {
    position: absolute;
    top: 25px;
    right: 30px
}

#iubenda_policy .iub_container>.iub_footer .btn {
    padding: 0px 24px;
    line-height: 29px
}

#iubenda_policy .iub_container>.iub_footer .button-stack {
    margin: -4px 0
}

#iubenda_policy .iub_container>.iub_footer .button-stack .btn+.btn {
    margin-left: 5px
}

#iubenda_policy .iub_container>.iub_footer img {
    margin: -4px 3px 0;
    vertical-align: middle;
    width: 70px;
    height: 25px
}

#iubenda_policy .wide {
    width: 150px
}

@media (max-width: 767px) {
    #iubenda_policy .legal_pp .one_line_col,#iubenda_policy .legal_pp .half_col {
        width:100%
    }

    #iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li,#iubenda_policy .legal_pp .half_col>ul.for_boxes>li {
        clear: both;
        width: 100%
    }

    #iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div,#iubenda_policy .legal_pp .half_col>ul.for_boxes>li:nth-child(2n+1)>div {
        margin-right: 0
    }

    #iubenda_policy .legal_pp .one_line_col>ul.for_boxes>li:nth-child(2n)>div,#iubenda_policy .legal_pp .half_col>ul.for_boxes>li:nth-child(2n)>div {
        margin-left: 0
    }

    #iubenda_policy .iub_header img {
        position: static;
        margin-bottom: 12.5px
    }

    #iubenda_policy .iub_content>.iub_footer .show_comp_link {
        position: static;
        display: inline
    }

    #iubenda_policy .iub_container>.iub_footer {
        padding: 20px
    }

    #iubenda_policy .iub_container>.iub_footer .btn {
        top: 15px;
        right: 15px
    }


    #iubenda_policy .iub_base_container>.close.small {
        width: 14px;
        line-height: 14px;
        border-radius: 9px;
        padding: 0
    }
}

@media (max-width: 480px) {
    html#iubenda_policy {
        padding:0
    }

    #iubenda_policy body {
        padding: 0
    }

    #iubenda_policy .iub_base_container,#iubenda_policy .iub_container {
        margin: 0
    }

    #iubenda_policy .half_col:nth-child(2n+1)>* {
        margin-right: 0
    }

    #iubenda_policy .half_col:nth-child(2n)>* {
        margin-left: 0
    }

    #iubenda_policy .one_line_col,#iubenda_policy .half_col {
        width: 100%
    }

    #iubenda_policy .one_line_col>ul.for_boxes>li,#iubenda_policy .half_col>ul.for_boxes>li {
        clear: both;
        width: 100%
    }

    #iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div,#iubenda_policy .half_col>ul.for_boxes>li:nth-child(2n+1)>div {
        margin-right: 0
    }

    #iubenda_policy .one_line_col>ul.for_boxes>li:nth-child(2n)>div,#iubenda_policy .half_col>ul.for_boxes>li:nth-child(2n)>div {
        margin-left: 0
    }

    #iubenda_policy .iub_header img {
        position: static;
        margin-bottom: 12.5px
    }

    #iubenda_policy .iub_content>.iub_footer .show_comp_link {
        position: static;
        display: inline
    }

    #iubenda_policy .iub_container>.iub_footer {
        padding: 10px;
        text-align: center
    }

    #iubenda_policy .iub_container>.iub_footer .btn {
        position: static;
        width: auto;
        display: block;
        margin: 10px auto 0 auto;
        max-width: 200px
    }

    #iubenda_policy .iub_container>.iub_footer.in_preview {
        padding: 30px 10px
    }

    #iubenda_policy .iub_content {
        padding-left: 20px;
        padding-right: 20px
    }


    #iubenda_policy .iub_base_container>.close.small {
        width: 14px;
        line-height: 14px;
        border-radius: 9px;
        padding: 0
    }
}

#iubenda_policy.iubenda_fixed_policy .iub_base_container {
    max-width: 800px
}

#iubenda_policy.iubenda_fixed_policy .iub_container {
    margin-left: auto;
    margin-right: auto;
    zoom:1}

#iubenda_policy.iubenda_fixed_policy .iub_container:before,#iubenda_policy.iubenda_fixed_policy .iub_container:after {
    display: table;
    content: "";
    zoom:1;*display: inline
}

#iubenda_policy.iubenda_fixed_policy .iub_container:after {
    clear: both
}

#iubenda_policy.iubenda_fluid_policy #wbars {
    overflow-y: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: auto
}

/* #iubenda_policy.iubenda_fluid_policy .iub_container {
    margin-top: 30px;
    margin-bottom: 30px
} */

#iubenda_policy.iubenda_fluid_policy .half_col:nth-child(2n+1)>* {
    margin-right: 0
}

#iubenda_policy.iubenda_fluid_policy .half_col:nth-child(2n)>* {
    margin-left: 0
}

#iubenda_policy.iubenda_fluid_policy .one_line_col,#iubenda_policy.iubenda_fluid_policy .half_col {
    width: 100%
}

#iubenda_policy.iubenda_fluid_policy .one_line_col>ul.for_boxes>li,#iubenda_policy.iubenda_fluid_policy .half_col>ul.for_boxes>li {
    clear: both;
    width: 100%
}

#iubenda_policy.iubenda_fluid_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div,#iubenda_policy.iubenda_fluid_policy .half_col>ul.for_boxes>li:nth-child(2n+1)>div {
    margin-right: 0
}

#iubenda_policy.iubenda_fluid_policy .one_line_col>ul.for_boxes>li:nth-child(2n)>div,#iubenda_policy.iubenda_fluid_policy .half_col>ul.for_boxes>li:nth-child(2n)>div {
    margin-left: 0
}

#iubenda_policy.iubenda_embed_policy .iub_base_container {
    background: none
}

#iubenda_policy.iubenda_embed_policy .iub_container>.iub_footer {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: none
}

#iubenda_policy.iubenda_embed_policy .expand-click {
    cursor: default
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .iub_base_container {
    color: #666
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h2 {
    font-size: 24px;
    padding-top: 50px
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h3 {
    color: #444;
    font-size: 20px;
    padding-top: 45px
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h4 {
    font-size: 16px;
    padding-top: 40px;
    color: #555
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h5 {
    font-size: 14px;
    padding-top: 35px;
    margin-bottom: 0;
    color: #666
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy h6 {
    font-size: 12px;
    color: #505050;
    text-transform: uppercase;
    padding-top: 32px;
    margin-bottom: 0
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions {
    margin-top: 60px !important
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content {
    padding: 25px 15px !important
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions .expand-content h4 {
    font-size: 15px !important
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions:before {
    content: "";
    border-top: 1px dotted rgba(0,0,0,0.1);
    display: block;
    margin: 0 -10px;
    position: relative;
    top: -45px
}

#iubenda_policy.iubenda_vip_policy.iubenda_terms_policy .definitions h2 {
    font-size: 13px;
    color: #333
}

#iubenda_policy.iubenda_vip_policy.iubenda_fixed_policy .iub_container {
    max-width: 660px;
    padding-top: 80px
}

#iubenda_policy.iubenda_vip_policy .iub_base_container {
    color: #595858
}

#iubenda_policy.iubenda_vip_policy p {
    font-size: 14px;
    line-height: 1.6
}

#iubenda_policy.iubenda_vip_policy .allcaps,#iubenda_policy.iubenda_vip_policy p.allcaps,#iubenda_policy.iubenda_vip_policy ul.allcaps li {
    font-variant: small-caps !important;
    font-weight: bold !important;
    font-size: 16px !important;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif !important
}

#iubenda_policy.iubenda_vip_policy ul li {
    font-size: 14px;
    line-height: 1.6
}

#iubenda_policy.iubenda_vip_policy h1 {
    font-size: 30px;
    color: #141414;
    line-height: 1.6;
    margin-bottom: 60px
}

#iubenda_policy.iubenda_vip_policy h2 {
    font-size: 18px;
    color: #141414;
    line-height: 1.6;
    padding-top: 50px;
    padding-bottom: 15px
}

#iubenda_policy.iubenda_vip_policy h3 {
    color: #141414;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px
}

#iubenda_policy.iubenda_vip_policy .legal_pp .one_line_col {
    padding-bottom: 50px
}

#iubenda_policy.iubenda_vip_policy .half_col:nth-child(2n+1)>* {
    margin-right: 0
}

#iubenda_policy.iubenda_vip_policy .half_col:nth-child(2n)>* {
    margin-left: 0
}

#iubenda_policy.iubenda_vip_policy .one_line_col,#iubenda_policy.iubenda_vip_policy .half_col {
    width: 100%
}

#iubenda_policy.iubenda_vip_policy .one_line_col>ul.for_boxes>li,#iubenda_policy.iubenda_vip_policy .half_col>ul.for_boxes>li {
    clear: both;
    width: 100%
}

#iubenda_policy.iubenda_vip_policy .one_line_col>ul.for_boxes>li:nth-child(2n+1)>div,#iubenda_policy.iubenda_vip_policy .half_col>ul.for_boxes>li:nth-child(2n+1)>div {
    margin-right: 0
}

#iubenda_policy.iubenda_vip_policy .one_line_col>ul.for_boxes>li:nth-child(2n)>div,#iubenda_policy.iubenda_vip_policy .half_col>ul.for_boxes>li:nth-child(2n)>div {
    margin-left: 0
}

#iubenda_policy.iubenda_vip_policy .definitions,#iubenda_policy.iubenda_vip_policy .iub_footer,#iubenda_policy.iubenda_vip_policy .for_boxes {
    color: #505762
}

#iubenda_policy.iubenda_vip_policy .definitions h3,#iubenda_policy.iubenda_vip_policy .definitions p,#iubenda_policy.iubenda_vip_policy .definitions li,#iubenda_policy.iubenda_vip_policy .iub_footer h3,#iubenda_policy.iubenda_vip_policy .iub_footer p,#iubenda_policy.iubenda_vip_policy .iub_footer li,#iubenda_policy.iubenda_vip_policy .for_boxes h3,#iubenda_policy.iubenda_vip_policy .for_boxes p,#iubenda_policy.iubenda_vip_policy .for_boxes li {
    font-size: 13px
}

#iubenda_policy.iubenda_vip_policy .w_icon_24 {
    background-image: none
}

#iubenda_policy.iubenda_vip_policy .box_10.expand .expand-click.w_icon_24 {
    padding-left: 10px
}

#iubenda_policy.iubenda_vip_policy .box_primary {
    border-color: #E0E0E0;
    border-bottom-color: #D3D3D3;
    -webkit-box-shadow: none;
    box-shadow: none
}

#iubenda_policy.iubenda_vip_policy .box_primary h3 {
    color: #333
}

#iubenda_policy.iubenda_vip_policy .tc-deactivated h1 {
    font-size: 20px;
    margin-bottom: 10px
}

#iubenda_policy.iubenda_vip_policy .legal_pp .one_line_col {
    padding-bottom: 21px
}
