.playlist-swiper.swiper-button-prev,
.playlist-swiper.swiper-button-next {
  color: #FFFDEF !important;
  font-size: 24px;
  width: 40px !important;
  top: calc(80%) !important;
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  stroke: #2B2B26 !important;
  stroke-width: 7px !important;
}

.playlist-swiper.swiper-button-prev {
  left: -10px !important;
}

.playlist-swiper.swiper-button-next {
  right: -10px !important;
}

.playlist-swiper.swiper-button-disabled {
  display: none !important;
}

.playlist-swiper.swiper-button-prev:hover,
.playlist-swiper.swiper-button-next:hover {
    width: 50px !important;
    height: 50px !important;
    top: calc(80% - 2px) !important;
}

.playlist-swiper.swiper-button-prev:hover {
  left: -15px !important;
}

.playlist-swiper.swiper-button-next:hover {
  right: -15px !important;
}

/* .swiper-button-prev,
.swiper-button-next {
  color: #FCE868 !important;
  font-size: 24px;
  width: 40px !important;
  top: calc(50% - 10px) !important;
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  stroke: #2B2B26 !important;
  stroke-width: 7px !important;
}

.swiper-button-prev {
  left: -2px !important;
}

.swiper-button-next {
  right: -2px !important;
}

.swiper-button-disabled {
  display: none !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    width: 50px !important;
    height: 50px !important;
    top: calc(50% - 12px) !important;
}

.swiper-button-prev:hover {
  left: -7px !important;
}

.swiper-button-next:hover {
  right: -7px !important;
} */