@import "@aws-amplify/ui-react/styles.css";

:root, [data-amplify-theme] {
  --amplify-fonts-default-variable: "Oswald" !important;
  --amplify-fonts-default-static: "Work Sans" !important;
  --amplify-colors-brand-primary-10: #FCE868 !important;
  --amplify-colors-brand-primary-20: #FCE868 !important;
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40) ;
  --amplify-colors-brand-primary-60: #F98050 !important;
  /* Primary color */
  --amplify-colors-brand-primary-80: #2C2B26 !important;  /* top left tab and bottom button */
  --amplify-colors-brand-primary-90: #2C2B26 !important; 
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px ;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: #2C363F !important; 
  --amplify-colors-border-secondary: #2c363f27 !important;
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-primary: #FCE868 !important;
  --amplify-colors-background-secondary: #e9e2ce35 !important;
  --amplify-components-tabs-item-border-color: #FCE868 !important;

  --amplify-radii-small: 6px !important;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 1.5px !important;
  --amplify-border-widths-medium: 4px !important;
  --amplify-border-widths-large: 8px;

  --amplify-components-button-primary-background-color: #fff194 !important;
  --amplify-components-button-primary-color: #bdb096 !important;
  --amplify-components-button-primary-active-background-color: #2C363F !important; 
  --amplify-components-button-primary-disabled-background-color: #2c363f4a !important;
  --amplify-components-button-primary-disabled-color: #2C363F !important;
  --amplify-components-button-primary-loading-background-color: #f98050de !important;
  --amplify-components-button-loading-color: #FFFDEF !important;
  --amplify-components-button-font-weight: 500 !important;
  /* --amplify-components-button-primary-loading-color: #2C363F !important; */

  --amplify-components-authenticator-router-border-color: #F98050 !important;
  --amplify-components-authenticator-router-border-width: 2px !important;

  --amplify-components-authenticator-form-padding: 32px 32px 8px 32px !important; 
}


.amplify-input:focus {
  border-color: #2c2b2600 !important;
  box-shadow: var(--amplify-components-fieldcontrol-focus-box-shadow);
}

.federated-sign-in-button {
  background-color: #fff8cd !important;
  /* border-color: #F98050 !important; */
}

.amplify-tabs-item {
  background-color: #FCE868 !important;
  border-color: #F98050 !important;
}

.amplify-tabs-item[data-state=inactive] {
  color: #2c2b26b7 !important;
  border-color: #FCE868 !important;
  font-weight: 500;
}

.amplify-input {
  height: 25px !important;
}

.amplify-textfield {
  width: 226px !important;
  font-weight: 300 !important;
}

.amplify-flex {
  align-items: center !important;
}

.federated-sign-in-container {
  align-items: normal !important;
}

.amplify-field__show-password {
  height: 25px !important;
  padding-block: 4.5px !important;
}

.amplify-button--primary {
  height: 30px !important;
  width: 226px !important;
}

.amplify-button--link {
  color: #2c2b26b7 !important;
}