/* COMMON */

/* Scrollbar Track */
.ed-playlist-song-container::-webkit-scrollbar,
.artist-top30-container::-webkit-scrollbar {
  width: 10px; /* width of the scrollbar */
}

/* Handle */
.song-results-container::-webkit-scrollbar-thumb,
.ed-playlist-song-container::-webkit-scrollbar-thumb,
.artist-top30-container::-webkit-scrollbar-thumb {
  background-color: #d3c3a6; /* color of the scrollbar handle */
  border-radius: 6px; /* roundness of the scrollbar handle */
}

/* Handle on hover */
.song-results-container::-webkit-scrollbar-thumb:hover,
.ed-playlist-song-container::-webkit-scrollbar-thumb:hover,
.artist-top30-container::-webkit-scrollbar-thumb:hover {
  background-color: #d3c3a6; /* color of the scrollbar handle on hover */
}

/* Button on the scrollbar (not supported in all browsers) */
.song-results-container::-webkit-scrollbar-button,
.ed-playlist-song-container::-webkit-scrollbar-button,
.artist-top30-container::-webkit-scrollbar-button {
  display: none; /* hide the up and down buttons */
}

/* Track */
.song-results-container::-webkit-scrollbar-track,
.ed-playlist-song-container::-webkit-scrollbar-track,
.artist-top30-container::-webkit-scrollbar-track {
  background-color: #F1E8D5; /* color of the scrollbar track */
  border-radius: 6px; /* roundness of the scrollbar track */
}

/* Corner */
.song-results-container::-webkit-scrollbar-corner,
.ed-playlist-song-container::-webkit-scrollbar-corner,
.artist-top30-container::-webkit-scrollbar-corner {
  background-color: transparent; /* color of the scrollbar corner */
}

/* SONG RESULTS CONTAINER */

/* Scrollbar Track */
.song-results-container::-webkit-scrollbar {
  width: 17px; /* width of the scrollbar */
}