/* COLORI */

.green-text-background{
  background: linear-gradient(120deg, #84CDC4 0%,  #84CDC4 100%);
  background-repeat: no-repeat;
  background-size: 100% 85%;
  background-position: 0 80%;
}

.yellow-text-background {
  color: #2C2B26;
  background: linear-gradient(120deg, #FCE868 0%,  #FCE868 100%);
  background-repeat: no-repeat;
  background-size: 100% 85%;
  background-position: 0 80%;
}

.white-text-background {
  color: #2C2B26;
  background: linear-gradient(120deg, #FFFDEF 0%,  #FFFDEF 100%);
  background-repeat: no-repeat;
  background-size: 100% 85%;
  background-position: 0 80%;
}

.word-appearing-highlight {
  position: relative;
  z-index: 0;
}

.word-appearing-highlight::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: #FFFDEF;
  z-index: -1;
  opacity: 0;
  transform: translateY(400%) translateX(-50%);
  transition: transform 1s ease, opacity 1s ease;
}

.word-appearing-highlight.appearing-highlight::after {
  transform: translateY(1px) translateX(-50%);
  opacity: 1;
}

@keyframes widenHighlights {
  0% {
    width: 100%;
  }
  50% {
    width: 117%;
  }
  100% {
    width: 112%;
  }
}

.word-appearing-highlight.widening-highlight::after {
  animation: widenHighlights 1s ease forwards;
}

.orange-color{
  color: #F98050;
}

.cream-color{
  color: #FFFDEF;
}

.cream-background-color{
  background-color: #FFFDEF;
}

.black-background-color{
  background-color: #2C2B26;
}

.yellow-background-color{ 
  background-color: #FCE868;
}

.new-yellow-background-color{ 
  background-color: #FCE868;
}

.yellow-grandient-background {
  background-color: linear-gradient(to bottom, #FFFDEF, #FCE868);
}

.orange-background-color{ 
  background-color: #F98050;
}

.new-yellow-background-color{ 
  background-color: #FCE868;
}

.orange-background-color{ 
  background-color: #F98050;
}

.yellow-color{
  color: #FCE868;
}

.beige-background-color{
  background-color: #D2C2A5;
}

/* HIGHLIGHT ON SELECT */

.orange-selction-all ::-moz-selection {
  color: #FFFDEF;
  background: #f18455f3
}

.orange-selection-all ::selection {
  color: #FFFDEF;
  background: #f18455f3
}

.seafoam-selection-all ::-moz-selection {
  color: #FFFDEF ;
  background: #84CDC4
}

.seafoam-selection-all ::selection {
  color: #FFFDEF ;
  background: #84CDC4
}

.seafoam-selection::-moz-selection {
  color: #FFFDEF ;
  background: #84CDC4
}

.seafoam-selection::selection {
  color: #FFFDEF ;
  background: #84CDC4
}

.yellow-selection::selection {
  color: #2C2B26;
  background: #FCE868
}

.yellow-selection::-moz-selection {
  color: #2C2B26;
  background: #FCE868
}

.no-selection::selection {
  /* color: #2C2B26; */
  background: #2c2b2600
}

.no-selection::-moz-selection {
  /* color: #2C2B26; */
  background: #2c2b2600
}

/* DIMENSIONE PAGINE MAX */

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: 120px;
  padding-left: 104px;
  padding-right: 104px 
}

.logo-header {
  height: 76px;
}

.header-user-credit-container {
  display: flex;
  align-items: center;
}

.back-button {
  margin-left: 40px;
  font-size: 30px;
}

.logo-footer {
  height: 162px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.bottom-footer {
  margin-top: auto;
}

.exact-page{
  min-height: calc(var(--vh, 1vh) * 100);
  min-width: 100%;
}

.double-exact-page{
  min-height: calc(var(--vh, 1vh) * 200);
  min-width: 100%;
}

.triple-exact-page{
  min-height: calc(var(--vh, 1vh) * 300);
  min-width: 100%;
}

.super-scrolling-page {
  min-height: calc(var(--vh, 1vh) * 100 + 2020px);
  min-width: 100%;
}

.exact-page-net-header{
  min-height: calc(var(--vh, 1vh) * 100 - 120px);  
  min-width: 100%;
}

.exact-page-net-header-footer{
  min-height: calc(var(--vh, 1vh) * 100 - 120px - 330px);
  min-width: 100%;
}

.exact-page-int-net-header{
  min-height: calc(var(--vh, 1vh) * 100 - 120px);  
  padding-top: 78px;
  padding-left: 124px
  /* min-width: 100%; */
}

.yellow-band-section{
  background-color: #FCE868;
  min-height: calc(var(--vh, 1vh) * 50);
  min-width: 100%;
}

.yellow-band-section-2thirds{
  background-color: #FCE868;
  min-height: calc(var(--vh, 1vh) * 67);
  min-width: 100%;
}

.home-intermediate-stunt-height {
  height: calc(var(--vh, 1vh) * 250);
}

.demo-page {
  padding-top: 100px;
  padding-left: 6.32%;
}
  
.home-page-2 {
  padding-top: 177px;
  padding-left: 164px;
}

.phone-2-page {
  min-height: calc(var(--vh, 1vh) * 100);  
  min-width: 100%;
}

.home-page-3{
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);  
  min-width: 100%;
  padding: 50px 120px;
  display: flex; /* Add flex display */
  flex-direction: column; /* Optional: To align content vertically in a column */
  justify-content: center; /* Optional: To align content horizontally in the center */
}

.purchase-page {
  padding-inline: 6.32%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}


.editorial-section {
  position: relative; /* Initially, keep it relative */
  top: 0;
  right: 0; /* Add this line to ensure it stays on the right side */
  width: 100%; /* Add this line to make it full-width */
}

.editorial-section.fixed {
  position: fixed; /* When fixed, set it to fixed */
  top: 0;
  right: 0; /* Add this line to ensure it stays on the right side */
  width: 100%; /* Add this line to make it full-width */
}

.cassette-page {
  max-width: 1680px;
  min-height: 750px;
}

.cassette-line {
  margin-left: 90px;
  margin-right: 90px;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 381px;
  max-width: 1550px;
  background: #EFE5D1;
  border-radius: 15px;
}

.cassette-img {
  border-radius: 15px;
}

.ed-playlist-card {
  height: 140px;
  width: 340px;
}

.ed-playlist-img-container {
  position: relative;
  height: 104px;
  width: 104px;
  margin-right: 9px;
  /* margin-top: 38px; */
}

.ed-playlist-full-btn {
  position:absolute;
  right: 7px;
  bottom: 7px;
  color: #FFFDEF;
  padding: 0px;
}

.ed-playlist-img {
  border-radius: 8px;
  width: 100%;
}

.ed-playlist-title {
  font-family: "Oswald";
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 11px;
}

.ed-playlist-song-container {
  max-height: 160px;
  overflow-Y: auto;
}
  
.home-page-4 {
  padding-top: 126px;
  /* z-index: 10; */
}

.phone {
  display: none;
}

.phone-inline {
  display: none;
}

.midsize {
  display: none;
}

.page-padding {
  padding-left: 121px;
  padding-top: 96px;
}

.page-padding-rb {
  padding-right: 121px;
  padding-bottom: 96px;
}
  
.custom-page-int-up, .custom-page-int-extra, .custom-page-songs {
  /* max-width: 65%;  */
  padding-left: 124px;
  padding-top: 110px;
  height: calc(var(--vh, 1vh) * 100 - 120px);  
  overflow: hidden;
}



.custom-row-compose {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items:flex-start;
  height:calc(var(--vh, 1vh) * 100 - 120px - 110px);
}

.custom-row-compose-limits {
  display: none;
}

.song-results-container {
  height: calc(var(--vh, 1vh) * 92 - 120px - 110px - 114px - 60px);
  width: 100%;
  overflow-y: auto;
}

.subtitle-hero-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.play-block {
  position: absolute;
  top: 35%;
  right: 6%;
  transform-origin: center center; /* Set the transform origin to the center */
  transform: rotate(0deg) scale(1);
  animation: playAnimation 13s linear infinite; /* Adjust duration as needed */
  z-index: 0;
  height: 600px;
}

@keyframes playAnimation {
  0%, 100% {
    opacity: 0;
    transform: rotate(0deg) scale(1);
  }
  30% {
    opacity: 1;
    transform: rotate(15deg) scale(0.7);
  }
  45% {
    opacity: 1;
    transform: rotate(7.5deg) scale(0.85);
  }
  60% {
    opacity: 0.73;
    transform: rotate(0deg) scale(1);
  }
}

.subtitle-hero-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cta-hero-container {
  transform: translateY(35%);
  margin-right: 90px;
}

.play-block {
  position: absolute;
  top: 35%;
  right: 6%;
  transform-origin: center center; /* Set the transform origin to the center */
  transform: rotate(0deg) scale(1);
  animation: playAnimation 13s linear infinite; /* Adjust duration as needed */
  z-index: 0;
  height: 600px;
}

@keyframes playAnimation {
  0%, 100% {
    opacity: 0;
    transform: rotate(0deg) scale(1);
  }
  30% {
    opacity: 1;
    transform: rotate(15deg) scale(0.7);
  }
  45% {
    opacity: 1;
    transform: rotate(7.5deg) scale(0.85);
  }
  60% {
    opacity: 0.73;
    transform: rotate(0deg) scale(1);
  }
}

.footer {
  height: fit-content;
  padding-inline: 129px;
  padding-top: 30px;
}

.pop-list {
  width: 500px;
  min-height: 800px;
  padding-top: 30px;
  padding-inline: 30px;
}

.pop-list-img-cas {
  width: 110px; 
  margin-bottom: 30px;
}
.pop-list-description {
  font-size: 30px;
}

.cta-editorial-space {
  margin-top: 30px;
  margin-bottom: 30px;
}

.songlist-container-pre {
  margin: 20px;
  padding-inline: 22%;
}

.mini-songbar {
  height: auto;
  min-height: 20px;
  width: 210px;
  border: 1px solid #CFBFA2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 2px 5px 3px 2px;
  /* margin-bottom: 5px; */
}

.mini-song-text {
  font-family: 'Work Sans';
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  width: 190px;
  white-space: pre-line;
}

.songbar-text {
  font-family: 'Work Sans' !important;
  font-size: 15.5px;
  color: #2C2B26;
  }

.original-moment {
  max-width: 60%;
}

.dedication-feature-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.dedication-textarea {
  max-width: 1040px;
  font-family: "Work Sans";
  font-weight: 300;
  font-size: 42px !important;
  padding: 24px;
}

.counter-font {
  font-size: 22px;
  margin-top: 0.5em;
  margin-left: 0.5em;
}

.cover-file-input {
  max-width: 500px;
  max-height: 120px;
  border-radius: 6px;
  margin-right: 20px;
}

.mantine-1qot7xx {
  /* cover file input font */
  font-family: "work sans";
  font-size: 22px !important;
  background-color: #F1E8D5;
  min-height: 64px;
  border: 2px solid transparent;
}

.mantine-1qot7xx:hover {
  border-color: #F98050;
}

.mantine-167ugbe {
  color: #2C2B26;
}

.dropcrop-cover-flexprop {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.cover-cropper {
  position: relative;
  align-self: flex-start;
  width: 600px;
  height: 380px;
}

.crop-confirm-spacing {
 margin-left: 30px;
}

.carousel-outer-size {
  width: 60%;
  height: 230px;
}

.carousel-cover-inner-size {
  width: 228px;
  height: 228px;
}

.avatar-action-flexprop {
  display: flex;
  flex-direction: row; 
  justify-content: start;
  align-items: center;
  flex-wrap: wrap-reverse;
}

.dropcrop-avatar-flexprop {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.dropcrop-avatar-outer-container {
 margin-right: 6.32vw;
}

.top-action {
  position: relative;
  z-index: 5 ;
}

.under-action {
  z-index: 4 !important;
}

.cropzone-flex-props {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop-avatar-dim {
  min-height: 300px;
  width: 300px;
}

.avatar-image {
  height: 220px;
  width: 220px;
}

/* font size 30pt bug safari */
.name-form {
  width: 350px;
  height: 50px;
  font-size: 25pt; 
}

.your-name-box {
  display: flex;
  flex-direction: column;
}

.your-name-form {
  border: solid;
  border-color: #2C2B26;
  border-width: 0.25px;
  border-radius: 8px;
  text-align: left;
  font-weight: bold;
  margin-right: 10px;
  margin-left: 30px;
  line-height: normal;
  color: #2C2B26;
  padding-left: 0.5em;
  width: 300px;
}
  
.your-name-title {
  font-family: "Work sans";
  padding-left: 0.5em;
  font-size: 26px;
  margin-left: 30px;
  margin-bottom: 6px;
}

.svg-play-name {
  height: 40px;
}

.pop-self-space {
  margin-bottom: 60px;
}

.header-save-user-space {
  width: 40px
}

.row-margin-block {
  width: max-content;
  margin-block: 8px;
}

.strong600 {
  font-weight: 600;
}
    

 /* TESTI MAX */

.font-title-Osw{
  font-family: 'Oswald', sans-serif ;
  font-weight: 400;
  font-size: 100px; 
  line-height: 120px; 
  letter-spacing: 0.0040em;
  background-color: transparent; 
}

.font-titleHome-Osw{
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  font-size: 85px;
  line-height: 100px;
  letter-spacing: 0.01em;
  margin-top: 70px;
  max-width: 1300px;
}

.font-title-OswMed{
  font-family: 'Oswald', sans-serif ;
  font-weight: 500;
  font-size: 100px; 
  line-height: 120px;
  /* letter-spacing: 0.0040em;  */
  /* letter-spacing: 40pt;  */
}

.font-subtitle-OswMed{
  font-family: 'Oswald', sans-serif ;
  font-weight: 500;
  font-size: 45px; 
  line-height: 60px;
  letter-spacing: 0.099em; 
}

.font-category-title {
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0.02em; 
  margin-bottom: 14px; 
  margin-top: 8px; 
  padding-left: 30px;
}

.font-subtitle-WSMed{
  font-family: 'Work sans', sans-serif ;
  font-weight: 300;
  font-size: 45px; 
  line-height: 60px;
  letter-spacing: 0.05em; 
}


.font-subtitle2-OswMed{
  font-family: 'Oswald', sans-serif ;
  font-weight: 600;
  font-size: 60px; 
  line-height: 84px;
  letter-spacing: 0.04em; 
}

.font-subT-OswReg{
  font-family: "Oswald" , sans-serif ;
  font-weight: 400;
  font-size: 60px; 
  line-height: 80px; 
  letter-spacing: 0.0050em;
  padding-top: 75px;
  padding-bottom: 20px;
}

.title-artist {
  font-family: "Oswald";
  font-size: 60px;
  font-weight: 500;
  line-height: 89px;
  letter-spacing: 0em;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 0;
}

.text-artist {
  font-family: "Work Sans";
  font-size: 44px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: center;
}

.text-artist-strong-italic {
  font-style: italic;
  font-weight: 600;
}

.font-subT-WS{
  font-family: 'Work sans', sans-serif ;
  font-weight: 400;
  font-size: 35px; 
  line-height: 42px; 
  letter-spacing: 0.0010em;
  max-width: 860px;
}

.font-contacts{
  font-family: 'Work sans', sans-serif ;
  font-weight: 300;
  font-size: 22px; 
  line-height: 25px; 
  letter-spacing: 0.0090em;
  color: #FFFCEB;
}

.sym-footer {
  margin-left: 153px;
}

.font-hd-menu {
  font-family: 'Work sans', sans-serif ;
  font-weight: 500;
  font-size: 20px; 
  line-height: 22px; 
  letter-spacing: 0.0090em;
}

.font-ft-menu{
  font-family: 'Work sans', sans-serif ;
  font-weight: 300;
  font-size: 27px; 
  line-height: 30px; 
  letter-spacing: 0.0090em;
  color: #fffceb;
}

.footer-menu{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.font-subT-Osw-mail{
  font-family: 'Oswald', sans-serif ;
  font-weight: 400;
  font-size: 35px; 
  line-height: 35px; 
  letter-spacing: 0.0010em;
  color: #FFFCEB;
}

.font-subT5-WS{
  font-family: 'Work sans', sans-serif ;
  font-weight: 300;
  font-size: 22px; 
  line-height: 24px; 
  letter-spacing: 0.0090em;
}

.font-subT1-WS{
  font-family: 'Work sans', sans-serif ;
  font-weight: 400;
  font-size: 32px; 
  line-height: 33px; 
}

.font-linetitle{
  font-family: 'Oswald', sans-serif ;
  font-weight: 500;
  font-size: 33px; 
  line-height: 35px;
  letter-spacing: 00.0040em;
}

.font-subT2-WS{
  font-family: 'Work sans', sans-serif ;
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;
  letter-spacing: 0.0010em;
}

.home-space-2 {
  padding-bottom:95px;
}

.home-space-3 {
  padding-top: 93px;
  padding-right: 164px;
}

.font-subT3-WS{
  font-family: 'Work sans', sans-serif ;
  font-weight: 500;
  font-size: 50px;
  line-height: 56px;
  letter-spacing: 0.0000em;
  padding-top: 50px; 
  padding-bottom: 12vh;
}

.font-title0-Osw
{
  font-family: 'Oswald', sans-serif ;
  font-weight: 500;
  font-size: 70px; 
  line-height: 84px; 
  letter-spacing: 0.0010em;
}

.font-title1-Osw {
  font-family: 'Oswald', sans-serif ;
  font-weight: 400;
  font-size: 70px; 
  line-height: 84px; 
  letter-spacing: 0.0010em;
}

.font-title2-Osw{
  font-family: 'Oswald', sans-serif ;
  font-weight: 500;
  font-size: 60px; 
  line-height: 75px; 
}

.font-title3-Osw{
  font-family: 'Oswald', sans-serif ;
  font-weight: 500;
  font-size: 54px; 
  line-height: 65px; 
}

.margin0 {
  margin: 0;
}

.first-up-elem-spacer{
  padding-top: 100px;
  margin-bottom: 57px;
}

.last-down-elem-spacer{
  padding-bottom: 100px;
}

.pop-up-card-space-down{
  margin-bottom: 100px; /* see  first-up-elem-spacer*/
  display: flex !important;
  flex-direction: column !important;
  box-shadow: none !important;
  width: 498px !important;
  height: 87px !important;
  padding: 15px !important;
  border-radius: 10px !important;
  align-items: center !important;
  margin-top: 11px !important;
  background-color: #FFFDEF !important;
}

.pop-card-expanded {
  justify-content: space-evenly;
  height: 174px !important;
  background-color: transparent !important;
}

.pop-size-card-outside {
  margin: 15px 30px;
  width: 470px;
}

.pop-size-card-inside {
  padding: 30px !important;
}

.size-card-space {
  height: 26px;
}

.editable-name {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #F98050;
  border: none;
  border-radius: 2px;
  outline: none;
  background-color: #FFF;
  box-shadow: 0 0 0 1px #D3C3A6;
  max-width: 215px;
}

.resume-card-header {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
}

.resume-card-name {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #F98050;
}

.resume-card-subtitle {
  font-family: "Work Sans";
  font-size: 18px;
  margin-block: 0;
  line-height: 18px;
}


.font-subT1-Osw{
  font-family: 'Oswald', sans-serif ;
  font-weight: 400;
  font-size: 40px; 
  line-height: 50px; 
  letter-spacing: 0.0030em;
}

.font-subT2-Osw{
  font-family: 'Oswald', sans-serif ;
  font-weight: 400;
  font-size: 30px; 
  line-height: 50px; 
  letter-spacing: 0.0010em;
}

.purchase-aftertitle {
  margin-bottom: 20px;
}

.font-subT2-Osw-mc-variant{
  font-family: 'Oswald', sans-serif ;
  font-weight: 400;
  font-size: 30px; 
  line-height: 50px; 
  letter-spacing: 0.0010em;
}

.home-space-1 {
  padding-top: 32px; 
  padding-bottom: 57px;
}

.font-subT3-Osw{
  font-family: 'Oswald', sans-serif ;
  font-weight: 400;
  font-size: 43px; 
  line-height: 56px; 
  letter-spacing: 0.0040em;
}

.font-box-cassette{
  font-family: 'Oswald', sans-serif ;
  font-weight: 400;
  font-size: 60px; 
  line-height: 80px; 
  letter-spacing: 0.0090em;
  margin-bottom: 80px;
  margin-left: 95px;
}

.font-subT4-Osw{
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 80px;
}

.font-subT5-Osw-WS{
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
}

.font-subT6-WS{
  font-family: 'Work Sans', sans-serif ;
  font-weight: 400;
  font-size: 30px; 
  line-height: 50px; 
}

.brand-name {
  margin-left: 24px;
  font-family: "Quicksand";
  font-size: 35px;
  font-weight: 550;
  margin-bottom: 0px;
}

.brand-name-footer {
  margin-left: 24px;
  font-family: "Quicksand";
  font-size: 60px;
  font-weight: 550;
  margin-bottom: 0px;
  color:#FFFCEB;
}

.hashtag {
  font-family: 'Work Sans';
  font-weight: 500;
  color: #2C2B26;
  font-size: 14px;
  line-height: 16px;
  padding: 0px 3px 2px;
  border-radius: 2px;
}

.secondaryBtn {
  border: none;
  font-family: 'Oxygen';
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0.0020em;
}

.bigSecondaryBtn {
  border: none;
  font-family: 'Oxygen';
  font-weight: 700;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  font-size: 50px;
  line-height: 72px;
  letter-spacing: 0.0040em;
}

.userBtn {
  font-size: 35px
}

.credit-icon {
  font-size: 19px;
  display: flex;
  position: absolute;
  margin-bottom: 27px;
  margin-left: 32px;
  cursor: pointer;
}

.mantine-Menu-dropdown {
  background-color: #FFFDEF;
  border-color: #E4D9C5;
  padding: 10px 15px;
}

.menu-dropdown-label {
  padding: 1px 6px; /* is to adapt to the LOG OUT button one */
}

.saving {
  text-decoration: none !important;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.saved {
  -webkit-text-fill-color: #FCE868 !important;
  text-decoration: none !important;
  }

.not-saved {
  -webkit-text-fill-color: #2c2b2663 !important;
  text-decoration: none !important;
  }

  /* ex-unofficial */
.redeemBtn {
  border-radius: 24px;
  border: none;
  width: 200px;
  height: 60px;
  font-family: "Work Sans";
  font-weight: 500;
  font-size: 29pt;
  line-height: 24pt;
  letter-spacing: 0.0090em;
}

  
.secondaryRedeemBtn {
  margin: 10px;
  color: #2C2B26;
  background-color: transparent;
  border-width: 1px;
  border-radius: 9px;
  border-color: transparent;
  min-width: 170px;
  padding-inline: 25px;
  font-family: 'Oxygen';
  font-weight: 400;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  font-size: 35px;
  line-height: 45px;
  letter-spacing: 0.04em;
}

.secondaryRedeemBtn.active:hover {
  border-color: #2C2B26;
  -webkit-text-fill-color: #FFFDEF;
}
  
.secondaryRedeemBtn.active.inside:hover {
  background-color: #FAF2CD;
}
  
  
.primaryBtnUncolored {
  border-radius: 24px;
  border: none;
  width: 260px;
  height: 60px;
  font-family: "Work Sans";
  font-weight: 500;
  font-size: 29pt;
  line-height: 24pt;
}

.primaryBtnColor {
  color: #FFFCEB;
  background-color: #F98050;
  transition: 0.3s ease;
}

.primaryBtnColorInactive {
  color: #FFFCEB;
  background-color: #F98050;
  opacity: 0.3;
  /* background-color: transparent; */
  /* transition: 0.3s ease; */
}

.wheel {
  width: 38px;
  height: 38px;
  font-size: 11.32px; /* margin-right 1 em */
  transition: 2.5s;
}

.wheel-reduced {
  width: 33.3px;
  height: 33.3px;
  font-size: 10px;/* margin-right 1 em */
  transition: 2.5s;
}

.wheelWhite {
  fill: #FFFCEB
}

.wheelOrange {
  fill: #F98050 !important
}

.wheelBtn {
  border-radius: 50ch;
  padding: 7.67px;
  font-family: 'Oswald';
  height: 54.3px;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0.08em;
  padding-inline-end: 0.67em !important;
  position: relative;
}

.wheelPrimary {
  border: none;
  width: min-content;
  transition: 1s;
  color: #FFFCEB;
  background-color: #F98050
}

.wheelPrimaryPre{
  color: #F98050;
  background: transparent;
  border: 2.5px solid #F98050;
  transition: 1s;
}

.wheelPrimaryPre:hover{
  color: #FFFCEB;
  background: #F98050;
  border: 2.5px solid #F98050;
  transition: 1s;
}

.smaller-br-span-phone {
  height: 2em !important;
  margin-bottom: 0px;
}

.wheelBtnSecondary {
  background: transparent;
  border: 2.5px solid #F98050;
  color: #F98050;
  transition: 1s;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.cartBtn {
  background: transparent;
  border: 2.5px dashed #F98050;
  color: #F98050;
  transition: 1s;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.cartBtn:hover {
  border: 2.5px solid #F98050;
}

.rec-big {
  width:50px;
  height:50px;
  font-size: 26px;
}

.rec-std {
  width:30px;
  height:30px;
  font-size: 16px;
}

.codeBlock {
  height: 70px;
  width: 70px;
  margin: 7px;
  border-radius: 6px;
  text-align: center;
  font-size:30px;
  font-family: 'Work Sans';
  font-weight: 500;
  /* text-transform: uppercase; */
  border: none;
  background-color: #FBF2BF;
  color: #2C2B26;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.shelf {
  height:10px;
  width: 530px;
  background-color: #D3C3A6;
  margin-top: 10px;
}

.sofa {
  height: 460px;
  margin-top: 80px ;
}

.phone-1 {
  height: 725px;
  padding-right: 233px;
}

/* DEMO SECTION */
/*  */
/*  */
/*  */
.fixed-demo-section {
  position: fixed;
  top: 100px;
  width: 93.68%;
  /* Additional styling for your fixed element when it's fixed */
}

.demo-section-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mockup-section {
  position: relative;
  display: inline-block;
  width: 650px;
  /* width:fit-content; */
  height: fit-content;
}

.frame {
  position: relative;
  top: 0;
  left: 0;
  /* width: 100%; */
  height: 725px;
  z-index: 2;
  pointer-events: none;
}

.frame-hidden {
  opacity: 0;
  z-index: 0;
}

.mockup-content-viewport {
  position: absolute;
  top: 16px;
  left: 4px;
  /* width: 100%; */
  width: 352px;
  height: calc(725px - 20px);
  border-radius: 60px;
  overflow: hidden;
  z-index: 1;
}

.content-image {
  position: relative;
  padding-inline: 20px;
  width: 100%;
}

.room-image {
  position: relative;
  padding-inline: 16px;
  width: 100%;
  z-index: 10;
}

.content-footer {
  position: absolute;
  top: 628px;
  padding-inline: 16px;
  width: 100%;
  z-index: 11;
}

.content2-image {
  position:absolute;
  top: 0;
  left: 0;
  padding-inline: 18px; /* because of the border of the image */
  z-index: 4;
}

.content3-image {
  top: -1px;
}

.mockup-cover {
  position: absolute;
  top: 231px;
  left: 59px;
  width: 238px;
  height: 238px;
  z-index: 4;
  border-radius: 14px;
}

.mockup-header {
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 28px 43px 0px 60px;
  z-index: 6;
}

.mockup-name {
  color: #FFFDEF;
  font-family: "Oswald";
  font-weight: 300;
  font-size: 25px;
  letter-spacing: 0.063em;
  padding: 12px 0px 0px 1.5px;
  max-width: 186px;
}

.mockup-avatar {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  z-index: 5;
}

.mockup-songs {
  position:absolute;
  top: 490px;
  left: 0;
  width: 100%;
  z-index: 5;
}

.gift-song {
  position: relative;
  width: auto;
  background-color: #2B2B26;
  padding-inline: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-inline: 6px;
  height: 60px;
  margin-bottom: 12px;
  z-index: 6000;
}

/* .fill-song-with-button {
  height: 60px !important;
} */

.interactive {
  height: 50px;
  margin-bottom: 9px;
}

.gift-song p {
  color: #FFFCEB;
  margin: 0;
  letter-spacing: 0.085em;
  font-size: 15px;
  line-height: 16px;
  font-weight: 300;
  padding-inline: 3px;
}

.interactive p {
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 2px;
}

.dedication-demo {
  position: absolute;
  top: 95px;
  left: 0;
  width: 100%;
  padding-inline: 50px;
  z-index: 4;
  font-family: 'Work sans', sans-serif ;
  font-weight: 300;
  font-size: 14px; 
  line-height: 17px; 
  letter-spacing: 0.06em;
  text-align: center;
}

.moment-demo {
  position: absolute;
  background-color: #F9E784;
  border-radius: 40px;
  top: 95px;
  left: 10px;
  width: 67%;
  margin-inline: 50px;
  padding: 12px;
  z-index: 4;
  font-family: 'Work sans', sans-serif ;
  font-weight: 300;
  font-size: 13px; 
  line-height: 15px; 
  letter-spacing: 0.06em;
  text-align: center;
  min-height: 119px;
}

.switch-container {
  position: fixed;
  /* bottom: 50px;
  right: 70px; */
  z-index: 1000;
}

.preview-container {
  position: fixed;
  width: fit-content;
  bottom: 50%;
  transform: translateY(55%);
  right: 6vw;
  z-index: 900;
}

/* .dedication-demo-font {
} */

.mockup-tooltip {
  position: absolute;
  right: 0px; 
  padding: 15px 13px 15px 22px;
  max-width: 270px;
  background-color: #fffdef;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0 !important;
  z-index: 3;
  font-family: "Work sans";
  font-size: 21px;
  line-height: 26px;
  letter-spacing: 0.02em;
  transform: translate(0%, -50%) !important;
  transition: opacity 0.15s ease;
  display: flex;
  align-items: center; /* Center vertically */

    /* Position the pointer on the left */
    &:before {
      content: "";
      position: absolute;
      top: 50%; /* Position the pointer in the center vertically */
      left: -8px; /* Adjust to position the pointer horizontally */
      transform: translateY(-50%);
      border-width: 8px 8px 8px 0; /* Adjust the size of the pointer */
      border-style: solid;
      border-color: transparent #fffdef transparent transparent; /* Match tooltip background color */
    }
}

.mockup-tooltip.active {
  opacity: 1 !important;
  transition: opacity 0.3s ease;
}

.mockup-circle {
  position: absolute;
  background-color: #F98050;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 1 !important;
  z-index: 5;
}

.mockup-circle.ready {
  opacity: 0.35 !important;
}

/* .mockup-circle:hover{
  opacity: 1 !important;
} */

/* .mockup-circle.active {
  opacity: 1 !important;
  animation: pulse 1 infinite;
} */

/* @keyframes pulse {
  0%, 100% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.5);
  }
} */

.vertical-progress-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 10px; /* Adjust the width of the progress bar */
  height: calc(var(--vh, 1vh)*100); /* Full height of the viewport */
  background-color: transparent; /* Change the color as needed */
  z-index: 2500;
}

.progress {
  width: 100%;
  background-color: #E4D9C5 !important; /* Change the color of the progress indicator */
  border-radius: 0px !important;
  left: 0;
  /* z-index: 2023; */
}
/*  */
/*  */
/*  */
/* END DEMO SECTION */

/* .compose-central {
  max-height: 100%;
} */

.phone-2 {
  margin-top: 90px;
  height: 853px;
  margin-bottom: 136px;
}

.tutorial {
  width: 600px;
  margin: auto;
}

.social {
  /* both for large and mid version to align box with contacts above */
  margin-right: 19.88px;
}

.social-icon {
  font-size: 80px;
  color: #FFFDEF
}

.social-icon:hover {
  color: #FCE868
}

.cookie-icon-hv:hover {
  color: #FCE868
}

.popup800 {
  width: 800px;
  height: 800px;
}

.search-wrapper {
  position:relative;
  margin-bottom: 12px;
  margin-top: 2px;
}

.search-input {
  font-family: 'Work Sans';
  letter-spacing: -0.02em;
  border-radius: 8px;
  border: hidden;
  height: 55px;
  width: 600px;
}

.search-input:focus {
  box-shadow: 0 0 0 2px #FCE868;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 0 !important; /* without this goes over the music player */
}

.songbar {
  width: 525px !important;
  margin: 3px 4px 11px 4px;
}

.songbar-text-space {
  width: 440px !important;
  padding-left: 3px; /* added to 16px (1rem) of the container */
}

.tooltip-text {
  font-family:'Work Sans';
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin: 5px;
}

.info-icon {
  font-size: 40px;
  color: #2C2B26 !important;
}

.switch-icon {
  font-size: 30px;
  margin-left: 6px;
  margin-top: 6px;
  color: #2C2B26 !important;
  transition: .15s ease-in-out 0.2s;
}

.half-rotation {
  transform: scaleY(-1) scaleX(-1);
}

.vertical-reverse {
  transform: scaleY(1) scaleX(-1);
}

.vibrate {
  animation: vibrate 0.1s linear infinite;
}

@keyframes vibrate {
  0% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(2px); }
}

.mail-box-disposition {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.your-mail {
  margin-right: 15px;
  text-align: right;
}

.input-mail {
  width: 700px;
  height: 50px;
  font-size: 25px;
  border: solid;
  border-color: #2C2B26;
  border-width: 0.25px;
  border-radius: 10px;
  padding-left: 25px;
  padding-bottom: 5px;
}

.email-error {
  font-size: 17px;
  color: #FF5733; /* check with design this color less vivid than pure red & well contrasting background */
  padding-left: 108px;
}

.cart-gift {
  width: 481.56px;
  height: 99.42px;
}

.gift-font {
  margin-left: 15px;
  margin-bottom: 0px;
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
}

.gift-name-font {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
}

.cart-cards {
  width: 480px;
  height: 96px;
}

.cart-cards-bottom {
  margin-bottom: 25px;
}

.payment-methods-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  border-radius: 10px !important;
}

.linetitle {
  margin-block: 16px 24px; 
}

.offer-claim {
  margin-bottom: 0;
  font-family: "Oswald";
  font-weight: 500;
  font-size: 17pt;
  line-height: 24pt;
}

.methodIcon{
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.methodCaption{
  display: block;
  font-size: 12px;
  font-family: 'Work Sans';
  font-weight: 400;
  padding-top: 2pt;
}

.font-gratitude {
  font-family: 'Work sans';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}



/* SPECIAL PLACES */

textarea::placeholder{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
}

textarea:focus {
    /* outline: none !important; */
  border-color: #F1E8D5;
  box-shadow: 0 0 10px #F1E8D5;
}

.radio-list {
  margin-top: 5px;
  margin-bottom: 5px !important;
}

.all-offer-text1-space {
  margin-bottom: 25px !important;
}

/* landing page 23 */
.invite-to-scroll {
  position: absolute;
  bottom: calc(var(--vh, 1vh) * 9);  
  left: 25%;
  transform: translateX(-50%);
  text-align: center;
}

.invite-to-scroll p {
  margin-bottom: 10px;
}

/* see boxCassetteNav.css for uniformity */
.invite-to-scroll .scroll-arrow {
  color: #FFFDEF;
  font-size: 24px;
  width: 40px !important;
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;
  stroke: #2B2B26 !important;
  stroke-width: 9px !important;
  transition: all 0.3s ease;
}

.invite-to-scroll .scroll-arrow:hover {
  transform: translateY(3px); /* Add hover animation */
  color: #FCE868;
  width: 50px !important;
  height: 50px !important;
  stroke-width: 7px !important;
}

/* navcheckbar */

.navCheckBar-container {
  position: fixed;
  top: 135px;
}

.navigation-bar {
  display: flex;
  justify-content: space-between;
  margin-left: 6.32vw; /* Adjust as needed */
}

.nav-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 2px solid #F98050;
  border-radius: 10px;
  margin-right: 30px;
  cursor: pointer;
  transition: all 0.5s ease;
  color: #F98050;
}

.nav-button.completed {
  background-color: #F98050;
}

.nav-button.active {
  background-color: #F98050;
  color: #FFFDEF;
}

.n-icon {
  margin-right: 10px; /* Adjust as needed */
  font-size: 30px;
  line-height: 30px;
  display: none;
}

.nav-button.completed .n-icon {
  display: block;
  color: #FFFDEF;
  margin-right: 0px;
}

.nav-button.active .n-icon {
  display: block;
  margin-right: 10px;
}

.n-label {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  letter-spacing: 0.03em;
}

.nav-button.completed .n-label {
  display: none;
}

.nav-button.active .n-label {
  display: block;
}

.music-control-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 90px;
  background-color: #CFBFA2;
  padding: 10px 31px 10px 20px;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-inline: 20px;
  margin-top: auto;
}

.mc-variant {
  height: 90px;
  flex-direction: column;
  justify-content: space-around;
  border: none;
}

.mc-play-pause-btn {
  height: 55px;
  width: 55px;
  border-radius: 8px;
  background-color: #FFFDEF;
  display: flex;
  justify-content: center;
  border: 0px;
  padding: 0px;
  margin-right: 12.5px;
}

.mc-play-icon {
  cursor: pointer;
  font-size: 30px;
  color: #F98050;
  margin-left: 4px; /* to compensate play shape*/
}

.mc-pause-icon {
  cursor: pointer;
  font-size: 30px;
  color: #F98050;

}

.mc-add-button {
  cursor: pointer;
  font-size: 24px;
  color: #FFFDEF; /* Adjust color as needed */
}

.chosed {
  color: #FCE868;
}

.mc-track-info {
  display: flex  !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  max-height: 50px;
  flex-grow: 1;
}

.mc-title {
  font-family: "Oswald";
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFDEF; /* Adjust color as needed */
  max-width: 300px;
  margin-bottom: 10px;
}

.mc-artist {
  font-family: 'Work Sans';
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #2C2B26; /* Adjust color as needed */
  margin-bottom: 4px;
}

.volume-control-container {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 125px;
}

.song-tool-container {
  width: 380px;
  background-color: #f1e8d5;
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  transition: height 0.3s ease;
}

.song-tool-open {
  height: 235px;
}

.song-tool-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}

.mantine-cx9mud,
.song-tool-title {
  font-family: 'Work Sans';
  font-size: 28px;
  font-style: italic;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #F98050;
}

.filter-category {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Work Sans';
  font-size: 17px;
  line-height: 20px;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 8px;
}

.reversed-arrow {
  transition: transform 0.15s;
  transform: rotate(180deg);
}

.normal-arrow {
  transition: transform 0.15s;
}

.song-tool-menu-icon {
  font-size: 24px;
}

.song-tool-divider {
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #d3c3a6;
}

.song-tool-content {
  padding: 0px 15px;
}

/* .tool-section {
  padding-top: 72px;
} */

.artist-tool-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.artist-tool-layout p {
  font-family: "Work sans";
  max-width: 122px;
  line-height: 1.3em;
}

.artist-top30-container {
  max-height: 150px;
  width: 215px;
  overflow-Y: auto;
  font-family: "Oswald";
}

.clickable-artist {
  cursor: pointer;
}

.clickable-artist:hover {
  color: #F98050;
}

.rolling-hashtag {
  display:flex;
  justify-content: start;
  flex-flow: row wrap;
  height: 80px;
}

.hero-img {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 80vh;
  width: 70vh;
  background-image: url("./assets/images/listener.png");
  background-size: cover;
  background-position: right;
  transform: translateY(13px);
  z-index: 1;
}

.hero-img-2 {
  position: absolute;
  bottom: -5px;
  right: 24vh;
  height: 80vh;
  width: 46vh;
  background-image: url("./assets/images/listener2.png");
  background-size: cover;
  background-position: right;
  z-index: 0;
}

.hero-img-22 {
  position: absolute;
  bottom: -13vh;
  right: -9.3vh;
  height: 60vh;
  width: 50vh;
  background-image: url("./assets/images/onhands.png");
  background-size: cover;
  background-position: right;
  z-index: 0;
  transform: rotate(3deg);
}

.feedback-slide {
  height: 300px;
}

.feedback-card {
  position: relative;
  border-radius: 40px;
  border: 1px solid #2C2B26;
  background-color: #FFFDEF;
  height: 250px;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: 55px;
}

.font-feedback {
  font-family: "Work sans";
  font-size: 28px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0.04em;
  text-align: left;
  padding-bottom: 1em;
}

.font-feedback-who {
  position: absolute;
  left: 59px;
  bottom: 29px;
  font-family: "Work sans";
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.04em;
  text-align: left;
  margin-bottom: 0;
}

.quote-marks {
  position: absolute;
  font-size: 267px;
  font-family: "Oswald";
}how-step

.quote-top {
  top: 10px;
  left: 90px;
}

.quote-bottom {
  bottom: 10px;
  right: 90px;
}

.how-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  margin-top: 10vh;
}

.how-step {
 display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  max-width: 360px;
  margin-inline: 40px;
}

.how-title{
  font-family: "Oswald";
  color: #F98050;
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-align: center;
  margin-bottom: 20px;
}

.how-number{
  font-family: "Oswald";
  background-color: #F98050;
  color: #FFFDEF;
  font-size: 44px;
  line-height: 44px;
  font-size: 700;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.how-text {
  font-family: "Work Sans";
  font-size: 28px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.08em;
  text-align: center;
}

.how-line {
  position: absolute;
  top: 32px;
  background-color: #F98050;
  width: 67%;
  height: 8px;
}

.cart-title-box {
  margin-block: 20px;
  width: 400px;
  height: 624px;
}

.cart-card-yellow {
  height: 624px;
  border-radius: 24px;
  margin-block: 20px;
  padding: 36px;
  background-color: #FCE868;
}

.cart-card-beije {
  width: 440px;
  background-color: #E7DCC7;
  border-radius: 24px;
  margin-block: 20px;
  padding: 36px;
  height: 624px;
}

.banner {
  width: 100%;
  height: 35px;
  /* background-color: #FEB8C3;
  color: #2B2C26; */
  /* background-color: #E74C3C; 
  color: #FFFDEF; */
  background-color: #F98050; 
  color: #FFFDEF;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.banner-text {
  font-size: 20px;
}


/* SOLO BIG DESKTOP e MID++ */
@media screen and (min-width: 1651px) {
  
  .accordion {
    font-size: 20px !important;
  }

  .accordion-button {
    font-size: 20px !important;
    padding: 30px 30px !important;
  }

  .bigger-faq {
    max-width: 1000px !important;
    font-size: 20px !important;
  }

  .no-desktop {
    display: none;
  }

}



@media screen and (min-width: 1200px) and (max-width: 1850px) {

  .no-mid {
    display: none;
  }

  /* DIMENSIONE PAGINE MID */

  .header {
    display: flex;
    justify-content: space-between;
    padding: 12px 94px 8px 94px;
    height: 85px;
  }
  
  .logo-header {
    height: 55px;
    /* fatto */
  } 

  .logo-footer {
    height: 101px;
    margin-bottom: 7px;
    margin-top: 7px;
    /* fatto */
  }

  .back-button {
    margin-left: 24px;
    font-size: 26.6px;
  }

  .bottom-footer {
    margin-top: 10px;
  }
  
  .exact-page{
    min-height: calc(var(--vh, 1vh) * 100);  
    min-width: 100%;
  }
  
  .exact-page-net-header{
    min-height: calc(var(--vh, 1vh) * 100 - 85px);  
    min-width: 100%;
  }

  .exact-page-net-header-footer{
    min-height: calc(var(--vh, 1vh) * 100 - 85px - 245px);  
    min-width: 100%;
  }
  
  .exact-page-int-net-header{
    min-height: calc(var(--vh, 1vh) * 100 - 85px);  
    padding-top: 78px;
    padding-left: 124px
    /* min-width: 100%; */
  }

  .demo-page {
    padding-top: 40px;
  }
    
  .home-page-2 {
    padding-top: 141px;
    padding-left: 0px;
    /* fatto */
  }
  
  .phone-2-page {
    min-height: calc(var(--vh, 1vh) * 100);  
    min-width: 100%;
  }
  
  .home-page-3 {
    min-height: calc(var(--vh, 1vh) * 100);  
    min-width: 100%;
    padding: 72px;
    display: flex; /* Add flex display */
    flex-direction: column; /* Optional: To align content vertically in a column */
    justify-content: center; /* Optional: To align content horizontally in the center */
}

.purchase-page {
  padding-bottom: 20px;
}
  
  .home-page-4 {
    padding-top: 139px;
    /* fatto */
  }

  .cassette-page {
    max-width: 1680px;
    min-height: 600px;
  }
  
  .cassette-line {
    margin-left: 40px;
    margin-right: 40px;
    /* padding-top: 40px;
    padding-bottom: 40px; */
    height: 293px;
    /* max-width: 1550px; */
    /* background: #D3C3A6;
    border-radius: 15px; */
    /* fatto */
  }
  
  .cassette-img {
    border-radius: 15px;
    height: 220px;
    /* fatto */
  }
    
  .phone {
    display: none;
    /* fatto */
  }

  .phone-inline {
    display: none;
  }

  .mid-pop-offer {
    width: 1250px !important;
    height: 700px !important;
  }

  .mid-pop-offer-small {
    padding-top: 62px !important;
  }

  .mid-offer {
    width: 525px !important;
    height: 140px !important;
    margin-bottom: 12px !important;
  }

  .all-offer-text1-space {
    margin-bottom: 15px !important;
  }

  .midphone-offer-text2-space {
    margin-bottom: 30px !important;
  }
      
  .custom-page-int-up, .custom-page-int-extra {
    /* max-width: 60%;  */
    height: calc(var(--vh, 1vh) * 100 - 85px);  
    padding-left: 0vw;
    padding-top: 110px;
  }

  .custom-page-int-up-process {
    padding-left: 7vw;
    padding-top: 70px;
  }

  .custom-page-songs {
    padding-left: 0vw;
    padding-top: 85px; 
    height: calc(var(--vh, 1vh) * 100 - 85px);  
    overflow: hidden;
  }

  .song-results-container {
    height: calc(var(--vh, 1vh) * 92 - 85px - 85px - 114px - 60px);
    width: 100%;
    overflow-y: auto;
  }

  /* .custom-row-compose {
    justify-content: space-around;
  } */

  .custom-row-compose-limits {
    display: block;
  }

  .first-column-top-margin {
    margin-top: 10px;
  }

  .subtitle-hero-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cta-hero-container {
  transform: translateY(calc(var(--vh, 1vh) * 13));  
  margin-right: 16vw;
}

.play-block {
  position: absolute;
  height: calc(min(600px, var(--vh, 1vh) * 78));  
  top: calc(var(--vh, 1vh) * 30);  
  right: calc(max(30px, 2% + (600px - (var(--vh, 1vh) * 78))/1.5));
}
  
  .footer {
    height: fit-content;
    padding-inline: 61px;
    padding-top: 20px;
      /* fatto */
  }

  .pop-list {
    min-height: 700px;
    padding-top: 20px;
    padding-inline: 20px;
  }

  .pop-list-img-cas {
    width: 90px; 
    margin-bottom: 20px;
  }
  .pop-list-description {
    font-size: 27px;
  }

  .cta-editorial-space {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .feedback-slide {
    height: 250px;
  }

  .feedback-card {
  height: 220px;
  max-width: 630px;
  padding-inline: 45px;
}

.font-feedback {
  font-size: 25px;
  font-weight: 400;
  line-height: 29.4px;
}

.font-feedback-who {
  font-size: 20px;
  line-height: 23px;
}

  /* navcheckbar */
  .navCheckBar-container {
    position: fixed;
    top: 95px;
  }
  
  .navigation-bar {
    display: flex;
    justify-content: space-between;
    margin-left: 6.32vw; /* Adjust as needed */
  }

  .nav-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 2px solid #F98050;
    border-radius: 10px;
    margin-right: 30px;
    cursor: pointer;
    transition: all 0.5s ease;
    color: #F98050;
  }

  .n-icon {
    margin-right: 10px; /* Adjust as needed */
    font-size: 25px;
    line-height: 20px;
    display: none;
 }

  .n-label {
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.03em;
  }

  .music-control-container {
    padding: 7px 31px 7px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-inline: 20px;
    margin-top: 0;
  }
  
  .mc-variant {
    height: 80px;
    border: none;
  }
  
  .mc-play-pause-btn {
    height: 52px;
    width: 52px;
    border-radius: 8px;
    background-color: #FFFDEF;
    display: flex;
    justify-content: center;
    border: 0px;
    padding: 0px;
    margin-right: 12.5px;
  }
  
  .mc-play-icon {
    cursor: pointer;
    font-size: 30px;
    color: #F98050;
    margin-left: 4px; /* to compensate play shape*/
  }
  
  .mc-pause-icon {
    cursor: pointer;
    font-size: 30px;
    color: #F98050;
  
  }
  
  .mc-add-button {
    cursor: pointer;
    font-size: 22px;
    color: #FFFDEF; /* Adjust color as needed */
  }
  
  
  .mc-track-info {
    display: flex  !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    max-height: 50px;
    flex-grow: 1;
  }
  
  .mc-title {
    font-family: "Oswald";
    font-size: 23px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFDEF; /* Adjust color as needed */
    max-width: 300px;
    margin-bottom: 9px;
  }
  
  .mc-artist {
    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #2C2B26; /* Adjust color as needed */
    margin-bottom: 4px;
  }
  
  .volume-control-container {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 95px;
  }

  .song-tool-title {
    font-size: 23px;
    line-height: 24px;
    /* covered over 1650 */
  }
  
  /* .song-tool-container {
    width: 380px;
    background-color: #f1e8d5;
    border-radius: 8px;
    margin-bottom: 16px;
    overflow: hidden;
    transition: height 0.3s ease;
  }
  
  .song-tool-open {
    height: 235px;
  }
  
  .song-tool-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  .mantine-cx9mud,
  .song-tool-title {
    font-family: 'Work Sans';
    font-size: 28px;
    font-style: italic;
    font-weight: 600;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #F98050;
  }
  
  .filter-category {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Work Sans';
    font-size: 17px;
    line-height: 20px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  
  .song-tool-menu-icon {
    font-size: 24px;
  }
  
  .song-tool-divider {
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #d3c3a6;
  }
  
  .song-tool-content {
    padding: 0px 15px;
  }
  
  .tool-section {
    padding-top: 72px;
  } */
  
  /* .artist-tool-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .artist-tool-layout p {
    font-family: "Work sans";
    max-width: 122px;
    line-height: 1.3em;
  }
  
  .artist-top30-container {
    max-height: 150px;
    width: 215px;
    overflow-Y: auto;
    font-family: "Oswald";
  } */

  .cover-cropper {
    width: 600px;
    height: 300px;
    margin-bottom: 200px;
  }  

  .carousel-outer-size {
    width: 60%;
    height: 190px;
  }
  
  .carousel-cover-inner-size {
    width: 188px;
    height: 188px;
  }
  
  .avatar-image {
    height: 220px;
    width: 220px;
  }

  .dropcrop-avatar-outer-container {
    margin-right: 3.00vw;
  }

  .drop-avatar-dim {
    min-height: 220px !important;
    width: 220px !important;
  }

  .your-name-form {
    font-size: 21pt;
    width: 270px;
  }

  .your-name-title {
    font-size: 22px;
  }
  
   /* TESTI MID */
  
  .font-title-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 100px; 
    line-height: 120px; 
    letter-spacing: 0.0040em;
    background-color: transparent; 
  }
  
  .font-titleHome-Osw{
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 70px;
    line-height: 90px;
    letter-spacing: 0.01em;
    margin-top: 47px;
    max-width: 730px;
    /* fatto */
  }
  
  .font-title-OswMed{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 100px; 
    line-height: 120px;
  }
  
  .font-subtitle-OswMed{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: 0.01em;
    /* fatto */
  }

  .font-category-title {
    font-size: 28px;
    line-height: 38px;
    margin-top: 0px; 
    margin-bottom: 8px;
    padding-left: 20px;
  }

  .font-subtitle-WSMed{
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: 0.05em; 
  }
  
  .font-subtitle2-OswMed{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 53px;
    line-height: 62px;
    letter-spacing: 0.0060em;
    /* fatto */
  }
  
  .font-subT-OswReg{
    font-family: "Oswald" , sans-serif ;
    font-weight: 400;
    font-size: 37px;
    line-height: 49px;
    letter-spacing: 0.0050em;
    padding-top: 40px;
    padding-bottom: 20px;
    /* fatto */
  }

  .title-artist {
    font-family: "Oswald";
    font-size: 47px;
    font-weight: 500;
    line-height: 75px;
    letter-spacing: 0em;
    margin-top: 40px;
    margin-bottom: 30px;
    padding: 0;
  }
  
  .text-artist {
    font-family: "Work Sans";
    font-size: 37px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .font-subT-WS{
    font-family: 'Work sans', sans-serif ;
    font-weight: 400;
    font-size: 35px; 
    line-height: 42px; 
    letter-spacing: 0.0010em;
    max-width: 860px;
  }

  .font-hd-menu {
    font-size: 16px; 
    line-height: 18px;
  }
  
  .font-contacts{
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 14px; 
    line-height: 15px; 
    letter-spacing: 0.0090em;
  }

  .sym-footer {
    margin-left: 91px;
  }

  .font-ft-menu{
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 20px; 
    line-height: 22px; 
    letter-spacing: 0.0090em;
  }

  .footer-menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .font-subT-Osw-mail{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 21px; 
    line-height: 21px; 
    letter-spacing: 0.0010em;
    /* fatto */
  }

  .font-subT5-WS{
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 16px; 
    line-height: 22px; 
    letter-spacing: 0.0090em;
  }

  .font-subT1-WS {
    font-size: 25px;
    line-height: 28px;
  }

  .font-linetitle{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 29px; 
    line-height: 30px;
    letter-spacing: 00.0040em;
  }
  
  .font-subT2-WS{
    font-family: 'Work sans', sans-serif ;
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
    letter-spacing: 0.0010em
    /* fatto */
  }
  
  .home-space-2 {
    padding-bottom:56px;
    /* fatto */
  }
  
  .home-space-3 {
    padding-top: 110px;
    padding-right: 0px
    /* fatto */
  }
  
  .font-subT3-WS{
    font-family: 'Work sans', sans-serif ;
    font-weight: 500;
    font-size: 31px;
    line-height: 36px;
    letter-spacing: 0.0000em;
    padding-top: 32px; 
    padding-bottom: 5vh;
    /* fatto */
  }

  .font-title0-Osw {
    font-size: 54px;
    line-height: 72px;
  }
  
  .font-title1-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 37px;
    line-height: 53px;
    letter-spacing: 0.0010em;
  }

  .mid-boost-ft1o {
    font-weight: 500 !important;
    font-size: 65px !important; 
    line-height: 74px !important; 
  }

  .midphone-boost-ft1o-2 {
    font-weight: 500 !important;
    font-size: 55px !important; 
    line-height: 68px !important; 
  }

  .midphone-credit-disclaimer {
    margin-bottom: 60px !important;
  }

  .midphone-credit-confirm-value {
    margin-bottom: 70px !important;
  }
  
  .font-title2-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 45px; 
    line-height: 58px; 
  }

  .font-title3-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 40px; 
    line-height: 50px; 
  }

  .first-up-elem-spacer{
    margin-bottom: 50px;
    padding-top: 65px;
    }

  .last-down-elem-spacer{
    padding-bottom: 65px; /* see  first-up-elem-spacer*/
  }

  .pop-up-card-space-down{
    margin-bottom: 65px; /* see  first-up-elem-spacer*/
  }

  .font-gratitude {
    font-family: 'Work sans';
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
  }
  
  .font-subT1-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 32px; 
    line-height: 40px; 
    letter-spacing: 0.0030em;
  }

  .info-icon {
    font-size: 32px
  }

  .switch-icon {
    font-size: 24px;
    margin-left: 4px;
  }
  
  .font-subT2-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 21px;
    line-height: 34px;
    letter-spacing: 0.0010em;
    /* fatto */
  }

  .purchase-aftertitle {
    margin-bottom: 8px;
  }

  .font-subT2-Osw-mc-variant{
    font-size: 22px;
    line-height: 27px;
  }
  
  .home-space-1 {
    padding-top: 20px; 
    padding-bottom: 37px;
    /* fatto */
  }
  
  .font-subT3-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 50px; 
    line-height: 56px; 
    letter-spacing: 0.0040em;
  }
  
  .font-box-cassette{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 37px;
    line-height: 50px;
    letter-spacing: 0.01em;
    margin-bottom: 66px;
    margin-left: 54px;
    /* fatto */
  }

  .dedication-textarea {
    max-width: 700px;
    font-family: "Work Sans";
    font-weight: 300;
    font-size: 26px !important;
    padding: 24px;
  }

  .counter-font {
    font-size: 19px;
  }

  .original-moment {
    font-size: 26px !important; 
    line-height: 28px !important;
  }

  .desktop {
    display: none
  }

  .midsize {
    display: block
  }
  
  .font-subT4-Osw{
    font-family: 'Oswald', sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 80px;
  }
  
  .font-subT5-Osw-WS{
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 60px;
  }

  .font-ready-midphone {
    font-size: 54px !important;
  }

  .font-ready-midphone-2 {
    width: 283px !important;
    font-size: 30px !important;
    line-height: 45px !important;
    /* letter-spacing: 2.21px !important; */
  }

  .container-ready-midphone-3 {
    width: 241px !important;
    height: 341px !important;
  }

  .img-phone-ready-4 {
    width: 583px !important;
    height: 583px !important; 
    object-position: -50px -171px !important;
  }

  .midphone-pop-gems {
    padding: 100px 12px 40px !important;
  }
  
  .font-subT6-WS{
    font-family: 'Oswald', sans-serif ;
    font-weight: 300;
    letter-spacing: 0.02em;
  }

  .less-margin-1 {
    margin: 6px !important;
  }
  
  .brand-name {
    margin-left: 20px;
    font-family: "Quicksand";
    font-size: 30px;
    font-weight: 550;
    margin-bottom: 0px;
  }
  
  .brand-name-footer {
    margin-left: 15px;
    font-family: "Quicksand";
    font-size: 37px;
    font-weight: 550;
    margin-bottom: 0px;
    /* fatto */
  }
  
  .secondaryBtn {
    border: none;
    font-family: 'Oxygen';
    font-weight: 700;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.8px;
    font-size: 24px;
    line-height: 28.8px;
    letter-spacing: 0.0020em;
  }
    
  .bigSecondaryBtn {
    border: none;
    font-family: 'Oxygen';
    font-weight: 700;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    font-size: 50px;
    line-height: 72px;
    letter-spacing: 0.0040em;
  }

  .userBtn {
    font-size: 30px
  }

  .credit-icon {
    font-size: 17.5px;
    display: flex;
    position: absolute;
    margin-bottom: 27px;
    margin-left: 28px;
    cursor: pointer;
  }
    
  .redeemBtn {
    border-radius: 24px;
    border: none;
    width: 160px;
    height: 45px;
    font-family: "Work Sans";
    font-weight: 500;
    font-size: 20pt;
    line-height: 20pt;
    letter-spacing: 0.0090em;
  }

  .secondaryRedeemBtn {
    -webkit-text-stroke-width: 1px;
    font-size: 22px;
    line-height: 28px;
    min-width: 106px;
    padding-inline: 16px;
  }

  .primaryBtnUncolored {
    border-radius: 24px;
    border: none;
    width: 220px;
    height: 50px;
    font-family: "Work Sans";
    font-weight: 500;
    font-size: 24pt;
    line-height: 20pt;
  }
  
  /* .wheel {
    font-size: 16px;
    width: 45px;
    height: 45px;
    transition: 2.5s;
  }

  .wheelBtn {
    padding: 5.5px;
    font-size: 22px;
    line-height: 22px;
  } */

  .smaller-br-span-phone {
    height: 1.4em !important;
    margin-bottom: 0px;
  }
  
  /* .rec-big {
    width:50px;
    height:50px;
    font-size: 26px;
  } */
  
  .rec-std {
    width:23px;
    height:23px;
    font-size: 12px;
  }
  
  .phone-1 {
    height: 447px;
    padding-right: 0px;
  }

/* DEMO SECTION */
/*  */
/*  */
/*  */
.fixed-demo-section {
  top: 40px;
}


.mockup-section {
scale: 0.75;
transform: translateY(-12.5%);
}

.mockup-tooltip {

    /* Position the pointer on the left */
    &:before {
      left: -7px; /* Adjust to position the pointer horizontally */
    }
}

/*  */
/*  */
/*  */
/* END DEMO SECTION */

.preview-container {
  scale: 0.85;
  transform: translateY(65%);
  right: 4.5vw;
}
  
  .phone-2 {
    margin-top: 69px;
    height: 567px;
    margin-bottom: 131px;
    /* fatto */
  }
  
  .tutorial {
    width: 450px;
    margin: auto;
    /* fatto */
  }
  
  .social-icon {
    font-size: 48px;
  }

  .popup800 {
    width: 650px;
    height: 650px;
  }

  .search-input {
    height: 45px;
    width: 420px; /*-80 */
  }

  .songbar {
    height: 32px !important;
    width: 380px !important; /*-60*/
  }

  .songbar-text-space {
    width: 325px !important; /*-60*/
  }

  .songbar-text {
    font-size: 13.5px;
    }

    .pop-self {
      padding-top: 130px !important;
    }

    .pop-self-space {
      margin-bottom: 40px;
    }

    .header-save-user-space {
      width: 26.6px
    }

  .cart-title-box {
    width: 350px;
    height: 550px;
  }
    
  .cart-card-yellow {
    height: 550px;
    margin-block: 20px;
    padding: 36px;
  }

  .cart-card-beije {
    width: 400px;
    height: 550px;
    background-color: #E7DCC7;
    border-radius: 24px;
    margin-block: 20px;
    padding: 36px;
  }

  .cart-gift {
    width: 400px;
    height: 85px;
  }

  .gift-font {
    margin-left: 15px;
    margin-bottom: 0px;
    font-family: "Work Sans";
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
  }

  .gift-name-font {
    font-family: "Oswald";
    font-weight: 500;
    font-size: 25px;
    line-height: 25px;
  }

  .cart-cards {
    width: 400px;
    height: 85px;
  }

  .cart-cards-bottom {
    margin-bottom: 15px;
  }

  .linetitle {
    margin-block: 8px 16px 
  }

  .offer-claim {
    font-size: 15pt;
    line-height: 21pt;
  }

  .font-price-gift-value {
    font-size: 23px !important;
    line-height: 16px !important;
  }

  .offer-big-font-price-gift-value {
    font-size: 35px !important;
    line-height: 40px !important;
  }

  .phone-pop-size-small {
    padding-top: 45px !important;
  }
  

  }
  

  @media screen and (min-height: 801px) and (max-width: 1850px) {
    /* DEMO SECTION */
  /*  */
  /*  */
  /*  */
  .fixed-demo-section {
    top: 80px;
  }

  .mockup-section {
  scale: 0.87;
  transform: translateY(-2%);
  }

  /*  */
  /*  */
  /*  */
  /* END DEMO SECTION */
}



  @media screen and (min-width: 1651px) and (max-width: 1850px) {

    /* DIMENSIONE PAGINE MID++ */
    
    .exact-page-net-header-footer{
      min-height: calc(var(--vh, 1vh) * 100 - 85px - 273px);
      min-width: 100%;
    } 

    /* DEMO SECTION */
    /*  */
    /*  */
    /*  */
    .fixed-demo-section {
      top: 80px;
    }

    .mockup-section {
    scale: 0.90;
    transform: translateY(-2%);
    }

    /*  */
    /*  */
    /*  */
    /* END DEMO SECTION */

    .font-subT-OswReg {
      font-size: 50px;
      line-height: 70px;
      padding-top: 60px;
      padding-bottom: 15px;
    }

    .font-subT1-WS {
      font-size: 32px;
      line-height: 33px;
    }

    .font-subT3-WS {
      font-size: 45px;
      line-height: 50px;
      padding-top: 40px;
      padding-bottom: 10vh;
    }

    .font-subtitle-OswMed {
      font-size: 40px;
      line-height: 55px;
    }

    .font-category-title {
      font-size: 31px;
      line-height: 41px;
      margin-bottom: 12px;
      margin-top: 4px;
    }

    .font-subtitle2-OswMed {
      font-size: 75px;
      line-height: 90px;
    }

    .font-title1-Osw {
      font-size: 60px;
      line-height: 72px;
    }

    .font-titleHome-Osw {
      font-size: 80px;
      line-height: 90px;
      max-width: 1050px;
    }

    .header {
      padding-left: 90px;
      padding-right: 90px;
    }

    /* .wheel {
      font-size: 14px;
      width: 60px;
      height: 60px;
    }

    .wheelBtn {
      padding: 6px;
      font-size: 30px;
      line-height: 30px;
    } */

    .smaller-br-span-phone {
      height: 1.3em !important;
      margin-bottom: 0px;
    }
  
    .font-contacts {
      font-size: 20px;
      line-height: 23px;
    }

    .sym-footer {
      margin-left: 50px;
    }
  
    .font-hd-menu {
      font-size: 18px;
      line-height: 20px;
    }
  
    .font-ft-menu {
      font-size: 24px;
      line-height: 27px;
    }
  
    .secondaryBtn {
      font-size: 26px;
      line-height: 32px;
    }
  
    .secondaryRedeemBtn {
      font-size: 30px;
      line-height: 38px;
    }

    .logo-footer {
      height: 120px;
    }

    .footer {
      padding-inline: 60px;
      padding-top: 20px;
    }

    .brand-name-footer {
      font-size: 40px;
    }

    .cta-hero-container {
      margin-right: 10vw;
    }

    .custom-page-int-up, .custom-page-int-extra {
      padding-left: 6.5vw;
      padding-top: 110px;
    }

    /* compose page values for mid to cut them in full 1200 / 1850 range */
    .search-input {
      height: 45px;
      width: 500px;
    }

    .songbar {
      height: 40px !important;
      width: 440px !important;
    }

    .songbar-text-space {
      width: 385px !important;
    }

    .songbar-text {
      font-size: 15.5px;
    }

    .font-subT5-WS{
      font-family: 'Work sans', sans-serif ;
      font-weight: 300;
      font-size: 20px; 
      line-height: 22px; 
      letter-spacing: 0.0090em;
    }

    .font-subT2-Osw-mc-variant{
      font-family: 'Oswald', sans-serif ;
      font-weight: 400;
      font-size: 27px;
      line-height: 33px;
      letter-spacing: 0.0010em;
      /* fatto */
    }

    .song-tool-title {
      font-size: 28px;
      line-height: 33px;
    }

  
}

@media screen and (max-height: 720px) { 
  
  .preview-container {
    scale: 0.75;
    transform: translateY(72%);
  }

  .how-title{
    font-size: 38px;
    line-height: 50px;
  }
  
  .how-text {
    font-family: "Work Sans";
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.08em;
    text-align: center;
  }

  .crop-dimensions {
    height: 320px !important;
  }

}

@media screen and (max-height: 660px) {
  
  .song-tool-header {
    padding-block: 7px;
  }

  .song-tool-title {
    font-size: 23px;
    line-height: 24px;
  }

  .radio-list {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .font-subT6-WS {
    font-size: 25px;
    line-height: 40px;
  }

  .feedback-slide {
    height: 230px;
  }

  .feedback-card {
  height: 210px;
  max-width: 600px;
  padding-inline: 45px;
}

.font-feedback {
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.font-feedback-who {
  font-size: 18px;
  line-height: 20px;
}

.preview-container {
  scale: 0.70;
  transform: translateY(78%);
}

.crop-dimensions {
  height: 270px !important;
}

.no-extralowmidheight {
  display: none;
}

}

@media screen and (min-width: 1201px) and (max-width: 1500px) {

  .no-lowmid {
    display: none;
  }

  .cart-card-yellow {
    width: 350px !important;
    height: 500px !important;
    margin-inline: 0px !important;
    margin-block: 20px !important;
    padding: 20px !important;
  }

  .cart-card-beije {
    width: 350px !important;
    height: 500px !important;
    margin-inline: 0px !important;
    margin-block: 20px !important;
    padding: 20px !important;
  }

  .cart-gift {
    width: auto !important;
    height: auto !important;
    /* margin-inline: 20px !important;
    margin-block: 20px !important;
    padding: 20px !important; */
  }

  /* .cart-cards-bottom {
    margin-bottom: 20px;
  } */

  .gift-font {
    font-size: 14px !important;
    line-height: 15px !important; 
  }

  .gift-name-font {
    font-size: 15px !important;
    line-height: 15px !important; 
  }

  .phone-card-icon {
    width: 90px;
  }

  .font-linetitle{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 25px; 
    line-height: 26px;
    letter-spacing: 0.0040em 
  }

  .linetitle {
    margin-block: 16px 24px 
  }
  
  .lowmid-font-price-gift-value {
    font-size: 16px !important;
    line-height: 17px !important;
  }
  
  .lowmid-tag {
    margin-left: 10px !important;
    width: 150px !important;
  }

  .lowmid-offer {
    width: 310px !important;
    height: 75px !important;
    margin-bottom: 20px !important;
  }

  .lowmid-offer-claim {
    font-size: 17px !important;
    line-height: 24px !important; 
  }

  .lowmid-rows {
    font-size: 11px !important;
    line-height: 12px !important;
  }

  .lowmid-saving {
    font-size: 11px !important;
    line-height: 12px !important;
  }

  .lowmid-equal {
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .phone-popup-less-margin {
    margin-top: 10px !important;
  }

  .phone-pop-less-H {
    height: 15px !important
  }

  .phone-total-card {
    font-size: 28px !important;
  }

  .phone-btn-big-as-sm {
    font-size: 30px !important;
  }

  .phone-pop-offer-small {
    padding-inline: 24px;
    padding-top: 40px !important;
  }

  .phone-pop-size-small {
    padding-inline: 24px;
    padding-top: 40px !important;
  }

  .all-offer-text1-space {
    margin-bottom: 15px !important;
  }

  .midphone-offer-text2-space {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1350px) {

  .no-extralowmid {
    display: none;
  }

  .crop-confirm-spacing {
    margin-left: 15px;
   }

  .nowordsright {
    display: none
  }

  .ed-playlist-img-container {
    height: 80px;
    width: 80px;
  }

  .song-tool-container {
    width: 356px;
  }

  .preview-container {
    scale: 0.70;
    right: 1vw;
  }

}


@media screen and (max-width: 1270px) {
  /* bug correction in 70px only */
.cta-hero-container {
  transform: translateY(calc(var(--vh, 1vh) * 9));  
  margin-right: 7vw;
}
}

/* solo MID e DESKTOP */
@media screen and (min-width: 1200px) {
  .big-offer-claim {
    margin-bottom: 0px;
    font-family: "Oswald";
    font-weight: 500;
    font-size: 34px;
    line-height: 50px;
  }

  .big-offer-claim-less-height-more-bottom {
    margin-bottom: 24px;
    line-height: 40px;
  }
}


@media screen and (max-width: 1200px) {

  /* DIMENSIONE PAGINE PHONE */

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    height: 75px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .logo-header {
    height: 45px;
  }

  .back-button {
    margin-left: 13px;
    font-size: 15px;
  }

  /* INIZIO FOOTER */

  .logo-footer {
    height: 70px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .brand-footer {
    /* width: 100%; */
    margin-block: 20px;
  }

  .bottom-footer {
    margin-top: 10px;
  }

  .rights-footer {
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 10px; 
    line-height: 10px; 
    letter-spacing: 0.0090em;
    text-align: center;
    color: #FFFCEB;
  }

  .footer {
    height:fit-content;
    padding-inline: 20px;
    padding-top: 10px;
  }
    
  .brand-name-footer {
    margin-left: 8px;
    font-family: "Quicksand";
    font-size: 26px;
    font-weight: 550;
    margin-bottom: 0px;
    /* display: none */
  }

  .social {
    /* both for large and mid version to align box with contacts above */
    margin: 15px 0px;
    /* margin-right: 19.88px; */
  }

  .social-icon {
    font-size: 55px;
    margin-right: 0px;
    margin-left: 15px;
  }

  .join-us-on-align {
    padding-left: 69.53px;
  }

  .font-hd-menu {
    font-size: 13px; 
    line-height: 14px; 
  }

  .font-contacts{
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 12px; 
    line-height: 13px; 
    letter-spacing: 0.0090em;
  }

  .sym-footer {
    margin-left: 0px;
  }

  .font-ft-menu{
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 16px; 
    line-height: 18px; 
    letter-spacing: 0.0090em;
  }

  .footer-menu{
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .font-subT-Osw-mail{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 16px; 
    line-height: 16px; 
    letter-spacing: 0.0010em;
  }

  .font-subT4-Osw{
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
  }

  .font-subT4-Osw-phoneplus{
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 31px;
    line-height: 34px;
  }

  /* only here in phone becomes WS */
  .font-subT5-Osw-WS{ 
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: 0.0010em; 
  }

  .font-subT5-Osw-WS-phoneplus{ 
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 23px;
    line-height: 25px;
    letter-spacing: 0.0010em; 
  }

  .font-ready-midphone {
    font-size: 32px !important;
  }

  .font-ready-midphone-2 {
    width: 230px !important;
    margin-right: 5px !important;
    font-size: 20px !important;
    line-height: 26px !important;
    letter-spacing: 1px !important;
  }

  .container-ready-midphone-3 {
    width: 124px !important;
    height: 176px !important;
  }

  .img-phone-ready-4 {
    width: 250px !important;
    height: 250px !important;
    object-position: -14px -65px !important;
  }



  /* FINE FOOTER */
  
  
  .exact-page{
    min-height: calc(var(--vh, 1vh) * 100);  
    /* min-width: 100%; */
  }

  .exact-page-net-header{
    min-height: calc(var(--vh, 1vh) * 100 - 75px);  
    /* min-width: 100%; */
  }

  .exact-page-net-header-navbar{
    min-height: calc(var(--vh, 1vh) * 100 - 75px - 53.28px);  
    /* min-width: 100%; */
  }

  .exact-page-net-header-footer{
    min-height: calc(var(--vh, 1vh) * 100 - 225px);  
    /* min-width: 100%; */
  }

  .exact-page-int-net-header{
    min-height: calc(var(--vh, 1vh) * 100 - 75px);  
    padding-top: 40px;
    padding-left: 32px
    /* min-width: 100%; */
  }

  .no-padding {
    padding-left: 0px !important;
  }

  .phone-asym-custom {
    padding-right: 48px;
  }

  
.page-padding {
  padding-left: 40px;
  padding-top: 60px;
}

.page-padding-rb {
  padding-right: 40px;
  padding-bottom: 60px;
}
  
  .custom-page-int-up{
    padding-left: 40px;
    padding-top: 20px;
    }

  .custom-page-int-up-phone-corr {
    padding-left: 0px;
    padding-top: 0px;
  }

  .custom-page-int-up-process {
    padding-left: 7vw;
    /* padding-top: 110px; */
  }

  .phone-anti-padding {
    padding: 0px
  }
  
  .custom-page-songs {
    padding-left: 0px; 
    padding-top: 20px;
  }

  .centered-process-pd-right {
    padding-right: 7vw;
  }

  .centered-group-phone {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
  }

  .custom-row-compose {
    justify-content: space-around;
    height: auto;
  }

  .custom-row-compose-limits {
    display: none;
  }
  
  .custom-page-int-extra{
    max-width: 100%; 
    padding-left: 40px;
    padding-top: 32px;
  }

  .subtitle-hero-row {
    display:block;
    height: calc(var(--vh, 1vh) * 100 - 261px);  
  }

  .phone-column {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
  }


.cta-hero-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  transform: translateY(-5%);
  margin-right: 0px;
  justify-content: center;
}

.invite-to-scroll {
  position: relative;
  bottom: 0px; 
  margin-bottom: 35px;
  left: auto; /* annulla regola precedente */
  transform: translateX(0); /* annulla regola precedente */
}

.play-block {
  position: absolute;
  top: 45%;
  right: 15%;
  height: 280px;
}

  .demo-page {
    padding-top: 35px;
    padding-left: 0;
  }
  
  .home-page-2 {
    padding-top: 61px;
    padding-inline: 34px;
  }
  
  .home-page-4 {
    padding-top: 220px;
    padding-left: 22px;
    padding-right: 22px;
  }

  .pop-list {
    min-height: 300px;
    max-width: 340px;
    padding-top: 20px;
    padding-inline: 0px;
  }

  /* .css-ypiqx9-MuiDialogContent-root {
    padding: 20px 0px !important
  } */
  
  .pop-list-img-cas {
    width: 60px; 
    margin-bottom: 20px;
  }

  .pop-list-description {
    font-size: 18px;
    padding-inline: 15px;
  }

  .cta-editorial-space {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .songlist-container-pre {
    margin: 0px;
    padding-inline: 0%;
  }

  .songlist-container-post {
    max-height: calc(var(--vh, 1vh) * 100 - 75px - 53.28px - 116px - 80px); /* net header navbar & content between nav and songlist and 90% of controller */
    overflow-y: auto;
  }

  .mini-songbar {
    height: 42px;
    width: 300px;
  }

  .mini-songbar {
    height: auto;
    min-height: 20px;
    width: 180px;
    border: 1px solid #CFBFA2;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 2px 5px 3px 2px;
    /* margin-bottom: 5px; */
  }

  .playlist-phone-viewer {
    max-height: 450px;
    overflow-y: auto;
  }
  
  .ed-playlist-card {
    height: 180px;
    width: 340px;
  }

  .ed-playlist-song-container {
    max-height: 200px;
    overflow-Y: auto;
  }

  .mini-song-text {
    white-space: pre-line;
    width: 161px;
  }

  .name-form {
    width: 150px;
    height: 30px;
    font-size: 15pt;
    /* fatto */
  }

  .your-name-title {
    font-size: 12pt;
    padding-left: 0.2em;
    margin: 0px 50px 4px 50px;
    margin-bottom: 4px;
    transform: translateY(-10px);
  }

  .svg-play-name {
    height: 20px;
  }

  .max-name-line {
    max-width: 270px;
    padding-bottom: 20px;
  }

  .cta-wrap {
    flex-flow: row wrap;
    width: 200px;
    row-gap: 10px;
  }

  /* navcheckbar phone */

  .navCheckBar-container {
    position: relative;
    top: auto;
  }

  .navigation-bar {
    display: flex;
    justify-content: space-around;
    margin-left: 0vw; /* Adjust as needed */
  }
  
  .nav-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 2px solid #F98050;
    border-radius: 10px;
    margin-right: 0px;
    cursor: pointer;
    transition: all 0.5s ease;
    color: #F98050;
  }

  .n-icon {
    margin-right: 0px; /* Adjust as needed */
    font-size: 25px;
    line-height: 25px;
    display: block;
  }
  
  .nav-button.completed .n-icon {
    display: block;
    color: #FFFDEF;
    margin-right: 0px;
  }
  
  .nav-button.active .n-icon {
    display: block;
    margin-right: 7px;
  }
  
  .n-label {
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.03em;
    display: none;
  }
  
  .nav-button.completed .n-label {
    display: none;
  }
  
  .nav-button.active .n-label {
    display: block;
  }

  /* songtool phone */
  
  .tool-section {
    padding-top: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 340px;
    justify-content: space-between;
  }

  .song-tool-container {
    width: 103px;
    height: 33px;
    border-radius: 8px;
    margin-bottom: 16px;
    overflow: hidden;
    transition: height 0.3s ease;
  }
  
  .song-tool-open {
    height: 235px;
  }
  
  .song-tool-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 6.5px 10px;
  }

  .sheet-section {
    position: absolute;
    height: 440px;
    width:  94vw;
    left: 3vw;
    right: 0px;
    bottom: 20px;
    z-index: 2000;
  }

  .song-sheet-header {
    justify-content: left;
    padding: 0px 5px 16px;
  }

  .song-tool-title {
    font-size: 18px;
    line-height: 20px;
  }

  .song-sheet-title {
    font-size: 24px;
    color: #2C2B26;
  }
  
  .filter-category {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Work Sans';
    font-size: 14px;
    line-height: 16px;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .artist-tool-layout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .artist-tool-layout p {
    max-width: 100%;
  }
  
  .artist-top30-container {
    max-height: 330px;
    width: 100%;
    overflow-Y: auto;
    padding-left: 50px;
    font-size: 18px;
  }

  .artist-top30-container li {
    margin-bottom: 6px;
  }
  
  .song-sheet-menu-icon {
    font-size: 24px;
    color: #CFBFA2;
    margin-right: 20px;
  }
  
  .song-tool-divider {
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #CFBFA2;
  }
  
  .song-tool-content {
    padding: 0px 15px;
  }

  .music-control-container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 13px 24px 16px 13px;
    margin-bottom: 0px;
    margin-top: auto;
    margin-inline: 7px;
    width: calc(100% - 14px);
  }
  
  .mc-variant {
    height: 90px;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    border: 1px solid #2C2B26;
  }
  
  .mc-play-pause-btn {
    height: 55px;
    width: 55px;
    border-radius: 8px;
    background-color: #FFFDEF;
    display: flex;
    justify-content: center;
    border: 0px;
    padding: 0px;
    margin-right: 12.5px;
  }
  
  .mc-play-icon {
    cursor: pointer;
    font-size: 30px;
    color: #F98050;
    margin-left: 4px; /* to compensate play shape*/
  }
  
  .mc-pause-icon {
    cursor: pointer;
    font-size: 30px;
    color: #F98050;
  
  }
  
  .mc-add-button {
    cursor: pointer;
    font-size: 24px;
    color: #FFFDEF; /* Adjust color as needed */
  }
  
  .mc-track-info {
    max-height: 50px;
    position: absolute;
    top: 11.5px;
    left: 80.5px;
  }
  
  .mc-title {
    font-family: "Oswald";
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFDEF; /* Adjust color as needed */
    max-width: 300px;
    margin-bottom: 00px;
  }
  
  .mc-artist {
    font-family: 'Work Sans';
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #2C2B26; /* Adjust color as needed */
    margin-bottom: 4px;
  }
  
  .volume-control-container {
    display: none;
  }

  .switch-container {
    position: absolute;
    /* bottom: 90px;
    right: 30px; */
    z-index: 1000;
  }
  
  .preview-container {
    scale: 1;
    position: fixed;
    bottom: 50%;
    left: 50%;
    transform: translateY(50%) translateX(-50%);
    z-index: 900;
  }

  .dark-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  }


  /* TESTI PHONE */

  .font-titleHome-Osw{
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 35px;
    line-height: 37px;
    letter-spacing: 0.01em;
    margin-top: 30px;
    max-width: 390px;
  }
  
  .font-subtitle-OswMed{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.0010em
  }

  .font-category-title {
    font-size: 22px;
    line-height: 28px;
    padding-left: 20px;
    margin-bottom: 8px;
  }

  .font-subtitle-WSMed{
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.05em; 
  }
  
  .font-subtitle2-OswMed{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 30px;
    line-height: 28px;
    letter-spacing: 0.0060em;
  }
  
  .font-subT-OswReg{
    font-family: "Oswald" , sans-serif ;
    font-weight: 400;
    font-size: 23px;
    line-height: 27px;
    letter-spacing: 0.0050em;
    padding-top: 28px;
    padding-bottom: 10px;
  }

  .title-artist {
    font-family: "Oswald";
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    margin-top: 80px;
    margin-bottom: 40px;
    padding: 0;
  }
  
  .text-artist {
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .text-artist-strong-italic {
    font-style: italic;
    font-weight: 600;
  }
  
  .font-subT2-WS{
    font-family: 'Work sans', sans-serif ;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.0010em
  }

  .font-subtitle3-WS {
    font-size: 14px !important;
    line-height: 17px !important;
    margin-bottom: 20px !important;
  }

  .home-space-2 {
    padding-bottom: 25px;
  }

  .home-space-3 {
    padding-top: 27px;
    padding-right: 0px;
  }
  
  .font-subT3-WS{
    font-family: 'Work sans', sans-serif ;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0.0000em;
    padding-top: 16px; 
    padding-bottom: 3vh;
  }
  
  
  .font-title1-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 25px;
    line-height: 34px;
    letter-spacing: 0.0010em;
  }

  .phone-boost-ft1o {
    margin-bottom: 30px !important;
    font-size: 37px !important;
    line-height: 45px !important;
    padding-inline: 10px;
  }

  .midphone-boost-ft1o-2 {
    font-weight: 500 !important;
    font-size: 28px !important; 
    line-height: 37px !important; 
  }

  .font-title0-Osw {
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }

  .font-title2-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 25px; 
    line-height: 34px;
  }

  .font-title3-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 32px; 
    line-height: 47px;
    margin-bottom: 0px;
    margin-left: 8px;
  }

  .centered-title-phone {
    justify-content: center;
    text-align: center;
  }

  .centered-name-phone {
    padding-left: 2px;
    text-align: center;
  }

  .first-up-elem-spacer{
    margin-bottom: 24px;
    padding-top: 40px;
  }

  .last-down-elem-spacer{
    padding-bottom: 40px; /* see  first-up-elem-spacer*/
  }

  .crop-confirm-spacing {
    margin-left: 50%;
    margin-top: 33px;
    margin-bottom: 150px;
    transform: translateX(-50%);
   }

  .carousel-outer-size {
    width: 100%;
    height: 190px;
  }
  
  .carousel-cover-inner-size {
    width: 190px;
    height: 190px;
  }

  .mantine-mupoyz {
    padding-inline: 4% !important;
  }

  .dropcrop-cover-flexprop {
    align-items: center;
  }

  .cover-file-input {
    margin-right: 0px;
  }
  
  .avatar-action-flexprop {
    justify-content: center;
  }
 
  .dropcrop-avatar-flexprop {
    align-items: center;
  }

  .dropcrop-avatar-outer-container {
    margin-right: 0vw;
   }
  
  .avatar-image {
    height: 220px;
    width: 220px;
  }

  .your-name-box {
    align-items: center;
  }

  .your-name-form {
    margin: 0px 50px 15px 50px;
    transform: translateY(-10px);
  }

  /* resume-card-header */
.resume-card-header {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 4px;
}

.resume-card-name {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #F98050;
}

.editable-name {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #F98050;
  border: none;
  border-radius: 2px;
  outline: none;
  background-color: #FFF;
  box-shadow: 0 0 0 1px #D3C3A6;
  max-width: 90px;
}

.resume-card-subtitle {
  font-family: "Work Sans";
  font-size: 8.75px;
  margin-block: 0px;
  line-height: 8.75px;
}

  .new-gift-font1 {
    font-family: "Oswald";
    font-weight: 400;
    font-size: 25px;
    line-height: 25px;
  }

  .font-subT1-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 18px; 
    line-height: 24px; 
    letter-spacing: 0.0030em;
  }

  .font-subT1-Osw-custom-phone {
    font-size: 21px !important; 
    line-height: 26px; 
  }
  
  .font-subT2-Osw {
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.0010em;
  }

  .purchase-aftertitle {
    margin-bottom: 4px;
  }

  .font-subT2-Osw-mc-variant{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.0010em;
  }

  /* name */
  .font-title-Osw{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 50px; 
    line-height: 60px; 
    letter-spacing: 0.0040em;
    background-color: transparent; 
  }

  
  .font-subT-WS{
    font-family: 'Work sans', sans-serif ;
    font-weight: 400;
    font-size: 18px; 
    line-height: 21px; 
    letter-spacing: 0.0010em;
    max-width: 300px;
  }

  .font-subT6-WS {
    font-family: 'Work Sans', sans-serif ;
    font-weight: 400;
    font-size: 18px; 
    line-height: 18px; 
  }

  .font-feedback {
    font-family: "Work sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 18.77px;
    letter-spacing: 0.04em;
    text-align: left;
    padding-bottom: 1em;
  }
  
  .font-feedback-who {
    position: absolute;
    left: 59px;
    bottom: 23px;
    font-family: "Work sans";
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 21.11px;
    letter-spacing: 0.04em;
    text-align: left;
    margin-bottom: 0;
  }

  .less-margin-1 {
    margin: 4px !important;
  }

  .form-check-input {
    margin-top: 0 !important;
    --bs-form-check-bg: #2C2B26 !important;
  }

  .radio-more-spacing {
    padding-left: 1.8rem !important;
  }

  .label-less-fontSize label {
    font-size: 0.95em !important;
  }

  .form-check.radio-more-spacing > .form-check-input {
    float: left;
    margin-left: -1.8rem; /* instead of default 1.5rem  */
  }

  .original-moment {
    max-width: 96%;
    font-size: 18px !important; 
    line-height: 18px !important;
    padding-left: 0.45rem !important;
    transform: translateX(-2px);
  }

  .original-check {
    transform: translateY(6px);
  }
  
  .mantine-1qot7xx {
    /* cover file input */
    font-size: 17px !important;
    height: 80px;
    width: 240px;
  }

  .avatar-image {
    height: 150px;
    width: 150px;
  }

  .home-space-1 {
    padding-top: 2px; 
    padding-bottom: 25px;
  }
  
  
  .font-box-cassette{
    font-family: 'Oswald', sans-serif ;
    font-weight: 400;
    font-size: 25px;
    line-height: 34px;
    letter-spacing: 0.000em;
    margin: 10px 25px
  }

  .dedication-feature-container {
    width: 300px;
    flex-direction: column;
  }

  .dedication-textarea {
    font-family: "Work Sans";
    font-weight: 300;
    font-size: 24px !important;
    padding: 24px;
  }

  .counter-font {
    font-size: 18px;
    margin-top: 0.2em;
    margin-right: 0.35em;
  }

  .no-phone {
    display: none;
  }
  
  .brand-name {
    margin-left: 8px;
    font-family: "Quicksand";
    font-size: 13px;
    font-weight: 550;
    margin-bottom: 0px;
  }

  .secondaryBtn {
    border: none;
    font-family: 'Oxygen';
    font-weight: 700;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.45px;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.0020em;
  }
  /* FIXME */
  
  .bigSecondaryBtn {
    border: none;
    font-family: 'Oxygen';
    font-weight: 700;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.0020em;
  }

  .userBtn {
    font-size: 20px
  }

  .credit-icon {
    font-size: 10px;
    display: flex;
    position: absolute;
    margin-bottom: 20px;
    margin-left: 20px;
    cursor: pointer;
  }

  .redeemBtn {
    border-radius: 24px;
    border: none;
    width: 110px;
    height: 30px;
    font-family: "Work Sans";
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.0040em;
  }

  .secondaryRedeemBtn {
    -webkit-text-stroke-width: 0.7px;
    border-width: 0.5px;
    font-size: 15px;
    line-height: 19px;
    min-width: 50px;
    padding-inline: 11px;
    border-radius: 6px;
  }

  .primaryBtnUncolored {
    border-radius: 12px;
    border: none;
    width: 130px;
    height: 30px;
    font-family: "Work Sans";
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
  }
  
  .wheel {
    font-size: 7px;
    width: 29.33px;
    height: 29.33px;
    transition: 2.5s;
  }

  .wheel-reduced {
    font-size: 7px;
    width: 26.33px;
    height: 26.33px;
    transition: 2.5s;
  }

  .wheelBtn {
    height: 42px;
    padding: 7px;
    font-size: 20px;
    line-height: 20px;
  }

  .wheelBtnSecondary {
    font-size: 16px;
    line-height: 16px;
  }

  .smaller-br-span-phone {
    height: 0.65em !important;
  }

  .rec-big {
    width:30px;
    height:30px;
    font-size: 16px;
  }

  .rec-std {
    width:13px;
    height:13px;
    font-size: 7px;
  }

  .codeBlock {
    height: 35px;
    width: 35px;
    margin: 3.5px;
    border-radius: 3px;
    text-align: center;
    font-size:15px;
  }

  .shelf {
    height:5px;
    width: 265px;
    background-color: #D3C3A6;
    margin-top: 7px;
  }

  .sofa {
    height: 130px;
  }
  

  .phone-1 {
    height: 500px;
    align-content: center;
    padding-top: 60px;
    padding-right: 0px;
    margin-inline: auto;
  }

  /* DEMO SECTION */
/*  */
/*  */
/*  */

.fixed-demo-section {
  top: 35px;
  width: 100%;
}

.demo-section-container {
  justify-content: space-around;
}

.mockup-section {
  scale: 0.635;
  transform: translateY(-14%);
  width: fit-content;
  display: flex;
  flex-flow: row wrap;
  }

.text-disappear {
  opacity: 0.15;
  transition: opacity 0.35s ease;
}
  
.mockup-tooltip {
  position: absolute;
  top: -125px !important;
  left: calc(50% - 193px);
  max-width: 386px;
  min-height: 130px;
  width: 400px;
  border-radius: 18px;
  font-size: 28px;
  line-height: 31px;
  letter-spacing: 0.02em;
  
    /* Position the pointer on the bottom */
    &:before {
      content: "";
      position: absolute;
      top: auto;
      bottom: -9px;
      left: 50%; /* Adjust to position the pointer horizontally */
      transform: translateX(-50%);
      border-width: 10px 10px 0; /* Adjust the size of the pointer and change the order */
      border-style: solid;
      border-color: #fffdef transparent transparent; /* Match tooltip background color */
    }
}

  .phone-demo-tooltip-0 {
    top: calc(-125px + 35px) !important;
    &:before {
      left: 70%;
    }
 }

  .phone-demo-tooltip-1 {
    top: calc(-125px + 35px) !important;
    &:before {
      left: 59%;
    }
  }

 .phone-demo-tooltip-2 {
  top: calc(-125px + 35px) !important;
    &:before {
      left: 14%;
    }
  }

 .phone-demo-tooltip-3 {
  top: calc(-125px + 20px) !important;
  &:before {
    left: 83.5%;
  }
}

.phone-demo-tooltip-4 {
  top: calc(-125px + 20px) !important;
  &:before {
    left: 83.5%;
  }
}

.phone-demo-tooltip-5 {
  top: calc(-125px + 2px) !important;
  &:before {
    left: 83.5%;
  }
}

.vertical-progress-bar {
  width: 6px;
}
  
  /*  */
  /*  */
  /*  */
  /* END DEMO SECTION */

  .phone-2-page {
    display: none;
  }

  .phone-2 {
    display: none;
  }

  .home-page-3{
    padding: 26px 31px 55px;
  }

  .purchase-page {
    padding: 0px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cassette-page {
    /* max-width: 500px; */
    min-height: 450px;
  }

  .cassette-line {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 182px;
    /* max-width: 500px; */
    /* background: #D3C3A6;
    border-radius: 15px; */
  }

  .cassette-img {
    border-radius: 15px;
    height: 141px;
  }

  .desktop {
    display: none;
  }

  .midsize {
    display: none;
  }

  .phone {
    display: block;
  }

  .phone-inline {
    display: inline;
  }

  .tutorial {
    width: 270px;
    margin: auto;
    padding-block: 50px;
  }

  .right-margin-phone {
    padding-right: 6.32%;
  }

  .popup800 {
    max-width: 340px;
    height: 340px;
  }

  .css-4pp0nb-MuiPaper-root-MuiDialog-paper,
  .css-1g5f7gc {
    margin: 0px !important;
    max-width: calc(100% - 12px) !important;
  }

  .tooltip-text {
    font-family:'Work Sans';
    text-align: center;
    font-weight: 500;
    font-size: 8px;
    margin: 3px;
  }

  .info-icon {
    font-size: 25px
  }

  .switch-icon {
    font-size: 19px;
    margin-left: 4px;
    margin-top: 6px;
  }

  .rolling-hashtag {
    display:flex;
    justify-content: space-between;
    flex-flow: row wrap;
    height: 100px;
  }

  .hashtag {
    font-family: 'Work Sans';
    font-weight: 500;
    color: #2C2B26;
    font-size: 14px;
    line-height: 16px;
    padding: 0px 3px 2px;
    border-radius: 2px;
  }

  .song-results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(var(--vh, 1vh) * 102 - 75px - 53.28px - 156px - 59px - 84px);
    overflow: visible;
  }

  .songbar {
    height: 40px !important;
    width: 305px !important;
  }

  .songbar-text-space {
    width: 200px !important;
    display: flex;
    flex-direction: column;
  }

  .songbar-text {
    font-family: 'Work Sans' !important;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-phone {
    height: 45px !important;
    width: 330px !important;
  }
    
  .phone-compose-central {
    width: 340px !important;
  }
    
  .phone-button-next-compose {
    margin-inline: 0px !important;
    padding-top: 30px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pop-rec {
    padding-inline: 15px !important;
    /* padding-block: 10px !important; */
     /*+20px block da .css-ypiqx9-MuiDialogContent-root  */
  }

  .pop-rec-space {
    height: 45px !important;
  }

  .pop-self {
    padding-inline: 40px !important;
    padding-top: 60px !important;
  }

  .pop-self-space {
    margin-bottom: 15px;
  }

  .header-save-user-space {
    width: 10px
  }

  .phone-dedication {
    font-size: 18px !important;
  }

  .radio-list {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .phone-av-ckbx-cntr {
    max-width: 220px;
    /* text-align: center; */
  }

  textarea::placeholder{
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }

  .extra-space-customize-page {
    padding-top: 90px;
  }

  .drop-avatar-dim {
    min-height: 220px !important;
    width: 220px !important;
  }

  .crop-dimensions {
    min-height: 250px !important;
    max-height: calc(100vh - 420px);
    width: calc(100vw - 2*7vw) !important;
  }

  .cropzone-flex-props {
    display: flex;
    direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .mail-form {
    width: 280px !important;
  }

  .font-subT1-WS{
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 18px; 
    line-height: 20px;
    letter-spacing: 0.0010em; 
    text-align: center;
  }
  

  .font-subT5-WS{
    font-family: 'Work sans', sans-serif ;
    font-weight: 300;
    font-size: 15px; 
    line-height: 20px; 
    letter-spacing: 0.0090em;
  }

  .pop-mail {
    padding-left: 32px !important;
  }

  /* .css-hz1bth-MuiDialog-container {
    width: 360px !important;
  } */
  /* FIXME */

  .mail-box-disposition {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .your-mail {
    margin-inline: 40px;
    text-align: center;
  }

  .input-mail {
    font-size: 16px;
    padding-left: 0px;
    text-align: center;
  }

  .email-error {
    padding-left: 0px;
    text-align: center;
  }

  .cart-title-box {
    width: 350px !important;
    height: fit-content !important;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .cart-card-yellow {
    width: 350px !important;
    height: fit-content !important;
    min-height: 300px !important;
    max-height: 500px !important;
    margin-inline: 0px !important;
    margin-block: 20px !important;
    padding: 20px !important;
  }

  .cart-card-beije {
    width: 350px !important;
    height: fit-content !important;
    min-height: 300px !important;
    max-height: 500px !important;
    margin-inline: 0px !important;
    margin-block: 20px !important;
    padding: 20px !important;
  }

  .cart-gift {
    width: auto !important;
    height: auto !important;
    /* margin-inline: 20px !important;
    margin-block: 20px !important;
    padding: 20px !important; */
  }

  /* .cart-cards-bottom {
    margin-bottom: 20px;
  } */

  .gift-font {
    font-size: 14px !important;
    line-height: 15px !important; 
  }

  .gift-name-font {
    font-size: 15px !important;
    line-height: 15px !important; 
  }

  .css-1iltaq3-MuiPaper-root-MuiCard-root {
    width: 330px !important;
  }

  .phone-card-icon {
    width: 90px;
  }

  .font-linetitle{
    font-family: 'Oswald', sans-serif ;
    font-weight: 500;
    font-size: 25px; 
    line-height: 26px;
    letter-spacing: 0.0040em 
  }

  .linetitle {
    margin-block: 16px 24px 
  }

  .phone-font-price-gift {
    font-size: 14px !important;
    line-height: 22px !important;
  }
  
  .font-price-gift-value {
    font-size: 16px !important;
    line-height: 17px !important;
  }

  .font-price-size {
    font-size: 20px !important;
    line-height: 20px !important;
  }
  
  .phone-tag {
    margin-left: 10px !important;
    width: 150px !important;
  }

  .phone-offer {
    width: 310px !important;
    height: 75px !important;
    margin-bottom: 20px !important;
  }

  .pop-up-card-space-down{
    margin-bottom: 40px; /* see  first-up-elem-spacer*/
    display: flex !important;
    box-shadow: none !important;
    direction: "" !important;
    width: 250px !important;
    height: 45px !important;
    padding: 8px !important;
    border-radius: 5px !important;
    margin-top: 6px !important;
  }

  .pop-card-expanded {
    justify-content: space-evenly;
    height: 90px !important;
  }

  .pop-size-card-outside {
    margin: 0px 30px 10px;
    width: 260px;
  }
  
  .pop-size-card-inside {
    padding: 15px 20px 10px !important;
  }

  .size-card-space {
    height: 10px;
  }

  .offer-claim {
    font-size: 17px !important;
    line-height: 24px !important; 
  }

  .size-claim {
    font-size: 18px !important;
    line-height: 24px !important; 
  }

  .big-size-claim {
    font-size: 24px !important;
    line-height: 25px !important;
  }

  .big-offer-claim-less-height-more-bottom {
    margin-bottom: 10px;
  }

  .phone-rows {
    font-size: 11px !important;
    line-height: 12px !important;
  }

  .phone-size-rows {
    font-size: 13px !important;
    line-height: 15px !important;
  }

  .phone-saving {
    font-size: 11px !important;
    line-height: 12px !important;
  }

  .phone-equal {
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .phone-total-card {
    font-size: 28px !important;
  }

  .phone-btn-big-as-sm {
    font-size: 30px !important;
  }

  .phone-pop-offer {
    width: 350px !important;
    height: 600px !important;
  }

  .phone-pop-offer-small {
    padding-inline: 24px;
    padding-top: 40px !important;
  }

  .phone-pop-size-small {
    padding-top: 16px !important;
  }

  .all-offer-text1-space {
    margin-bottom: 15px !important;
  }

  .midphone-offer-text2-space {
    margin-bottom: 30px !important;
  }

  .phone-popup-less-margin {
    margin-top: 10px !important;
  }

  .phone-pop-less-H {
    height: 15px !important
  }

  .midphone-pop-gems {
    padding: 35px 12px !important;
  }

  .midphone-credit-disclaimer {
    margin-bottom: 35px !important;
  }

  .phone-credit-confirm {
    font-size: 22px !important;
    line-height: 14px !important;
    margin-bottom: 11px !important;
  }

  .midphone-credit-confirm-value {
    font-size: 22px !important;
    line-height: 14px !important;
    margin-bottom: 45px !important;
  }

  .phone-pop-thanks {
    padding-inline: 20px !important;
  }

  .font-gratitude {
    font-family: 'Work sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }

  .phone-thanks-H {
    height: 50px !important;
    display: none;
  }

  .phone-first-cta {
    padding-left: 20px;
  }

  .hero-img {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 45vh;
    width: 43vh;
    background-image: url("./assets/images/listener.png");
    background-size: cover;
    background-position: right;
    z-index: 0;
    transform: translateY(5px);
  }

  .hero-img-2 {
    position: absolute;
    bottom: -5px;
    right: 75px;
    height: 45vh;
    width: 28vh;
    background-image: url("./assets/images/listener2.png");
    background-size: cover;
    background-position: right;
    z-index: 0;
  }

  .hero-img-22 {
    position: absolute;
    bottom: -65px;
    right: -85px;
    height: 40vh;
    width: 32vh;
    background-image: url("./assets/images/onhands.png");
    background-size: cover;
    background-position: right;
    z-index: 0;
    transform: rotate(3deg);
  }


  .feedback-slide {
    height: 250px;
  }

  .feedback-card {
    position: relative;
    border-radius: 40px;
    border: 1px solid #2C2B26;
    background-color: #FFFDEF;
    height: 216px;
    max-width: 306px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 40px;
  }

  .feedback-phone-margin {
    margin-top: 70px;
  }

  .quote-marks {
    position: absolute;
    font-size: 140px;
    line-height: 140px;
    font-family: "Oswald";
  }
  
  .quote-top {
    top: 24px;
    left: 24px;
  }
  
  .quote-bottom {
    bottom: 24px;
    right: 24px;
  }

  .phone-how-flow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

 
  .how-steps {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    margin-top: 0;
    height: 60vh;
  }
  
  .how-step {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: start;
     height: 120px;
     width: 104%;
     margin-inline: 0px;
   }
  
  .how-title{
    font-family: "Oswald";
    color: #F98050;
    font-size: 28px;
    line-height: 40px;
    font-weight: 600;
    letter-spacing: 0.08em;
    text-align: center;
    margin-bottom: 2px;
  }
  
  .how-number{
    font-family: "Oswald";
    background-color: #F98050;
    color: #FFFDEF;
    font-size: 30px;
    line-height: 30px;
    font-size: 700;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  
  .how-text {
    font-family: "Work Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-align: left;
    width: 250px;
  }
  
  .how-line {
    position: absolute;
    left: 19px;
    background-color: #F98050;
    width: 6px;
    height: 67%;
    transform: translateY(2vh);
  }

  .banner {
    height: 32px;
  }

  .banner-text {
    font-size: 16px;
  }
  


}



/* AVATAR DRAG COLORS */

.drop-cover-colors {
  background-color: #F1E8D5;
  border: solid;
  border-width: 2px;
  border-color: transparent
  }

.drop-cover-colors:hover {
  background-color: #f8f2cd;  /* TODO is to change with the chosed one in searchBar */
  border-color: #FCE868;
}
  
.mantine-13bpxe2 {
  color: #2C2B26
}   


/* COMPONENTI */

.search-icon {
  background: transparent;
  padding: 4px;
  position: absolute;
  box-sizing:border-box;
  top:50%;
  left:2px;
  transform: translateY(-50%);
}

button[type="other-button"]:hover{
  text-decoration: underline;
}

.containerPage {
  color: #2C2B26;
  background-color: #FFFDEF;
}

/* container bottoni trasparenti */
.transparentBtn {
  background: transparent;
  border: none !important;
}

.btnFixPadding {
  padding: 0px 0px !important;
}

.textFixMargin {
  margin: 0px !important;
}

.transparentBtn:hover{
  text-decoration: underline;
}

.transparentBtn-conditionalHv:hover{
  text-decoration: none;
}

.transparentBtn-conditionalHv:hover .conditionalHv{
  text-decoration: underline;
}

.cipBtn {
  background: transparent;
  border: none !important;
  line-height: 16px;
  max-height: 18px;
}

.newGiftBtn {
  background: transparent;
  border: none !important;
}

.wheelPrimary:hover{
  transition: 0.1s;
  box-shadow: 0 0 6px rgba(43, 43, 38, 0.4);
}

.lightShadow:hover{
  box-shadow: 0 0 6px rgba(255, 255, 255, 0.5);
}

.wheelBtnSecondary:hover{
  transition: 0.1s;
  box-shadow: 0 0 6px rgba(43, 43, 38, 0.4);
}

.methodBtn {
  background: transparent;
  border: none;
  margin-bottom: 25px;
  border-radius: 10px;
  padding: 0px;
}

.methodBtn:hover{
  text-decoration: underline;
}

.cardBtn{
  border-width: 2px !important;
}

.methodBtn:focus .cardBtn{
  border-color: #F98050;
}

.redeemBtn:hover {
  color: #FBF2BF !important
}

.wheelPrimary:hover .wheel, 
.wheelBtnSecondary:hover .wheel,
.wheel:hover {
  -o-transform:rotate(360deg);
  -ms-transform:rotate(360deg);
  -moz-transform:rotate(360deg);
  -webkit-transform:rotate(360deg);
  transform:rotate(360deg);
}

svg:hover, svg:focus {
  filter: url(#inset-shadow);
}

.active-filter {
  filter: url(#inset-shadow);
}

/* Per far comparire pulsante di modifica su avatar onHover
https://codepen.io/philcheng/pen/YWyYwG */

.containerAvatar {
  position: relative;
  /* margin-top: 50px;
  width: 500px;
  height: 300px; */
}

.overlayAvatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.containerAvatar:hover .overlayAvatar {
  display: block;
  background: rgba(0, 0, 0, .3);
}

.buttonAvatarCrop {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}

.buttonAvatarNew {
  position: absolute;
  left: 104%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
}

.containerAvatar:hover .buttonAvatarCrop {
  opacity: 1;
}

.containerAvatar:hover .buttonAvatarNew {
  opacity: 1;
}

.cover-slide-default {
  background: transparent;
  padding: 1px;
  border-width: 5px;
  border-radius: 14px;
  border-style: solid;
}

/* nuove regole styling generale */

.page {
  height: 1080px;
}

.min-short-page {
  height: auto;
}

.dedication.form-control {
  border-color: transparent !important;
  border-width: 2px;
}

.dedication.form-control:focus {
  box-shadow: none !important;
  border-color: #F98050 !important;
}

.moment.form-control {
  /* border-color: transparent !important; */
  border-width: 2px;
}

.moment.form-control:focus {
  box-shadow: none !important;
  border-color: #F98050 !important; 
  background-color: #f1e8d5e5 !important ;
}

.check.form-check-input {
  border-color: #D3C3A6;
}

.check.form-check-input:checked {
  border-color: #D3C3A6;
  box-shadow: 0 0 0 0.1rem #F98050;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23B8AA91%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
  }

.check.form-check-input:focus {
border-color: #F98050;
box-shadow: 0 0 0 0.1rem #F98050;
}

/* takes precedence in chrome */
input:focus-visible {
  outline: #F98050 solid 2px}

/* takes precedence in safari */
input:focus {
  outline: none;
  border-color: #F98050;
  box-shadow: 0 0 0 2px #F98050;
}

/* takes precedence in chrome */
.name-form:focus-visible {
  outline: transparent solid 2px}

/* takes precedence in safari */
.name-form:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 2px transparent;
}

/* takes precedence in chrome */
.your-name-form:focus-visible {
  outline: #2C2B26 solid 1px}

/* takes precedence in safari */
.your-name-form:focus {
  outline: none;
  border-color: #2C2B26;
  box-shadow: 0 0 0 1px transparent;
}

/* COVER FILEINPUT */

.mantine-1qot7xx:focus, .mantine-1qot7xx:focus-within {
  outline: none;
  border-color: #F98050 !important;
}

.mantine-cgics6 {
  background-color: #f8f2cd; /* TODO is to change with the chosed one in searchBar */
  border-width: 2px ;
/* border-color: #2C2B26; */
}

.mantine-cgics6:focus-within {
  border-color: #FCE868 !important;
}

.mantine-1c1sj6u {
/* placeholder color */
  color: #666458
}

.mantine-1hseney {
/* icon color */
  color: #666458
}

.mantine-a46dzg {
  /* carousel slide */
  padding-right: 12px
}

.mantine-5lag56 {
/* carousel height */
height: 242px;
}

/* AVATAR FILE INPUT */
.mantine-11zytr1 {
  flex-direction: column;
}

/* SWITCH TOGGLE */

/* xl, lg dimensions rules */
.mantine-g1sngq,
.mantine-adpl6b {
  /* toggle */
  background-color: #2B2B26;
  border-color: #2B2B26;
}

input:checked+.mantine-g1sngq,
input:checked+.mantine-adpl6b {
  /* toggle checked */
  background-color: #FFFDEF;
  border-color: #2B2B26;
}

.mantine-Switch-thumb {
  background-color: #FFFDEF;
  border: 1px solid #FFFDEF;
  transition: 0.4s ease-in-out;
}

.alt .mantine-Switch-thumb {
  background-color: #F98050;
  border: 1px solid #F98050;
}

@keyframes flash {
  0%, 100% {
    background-color: #2C2B26;
    border: 1px solid #2C2B26;
  }
  36% {
    background-color: #FCE868;
    border: 1px solid #FCE868;
  }
}

.hey .mantine-Switch-track {
  animation: flash 1s ease-in-out;
}

input:checked+*>.mantine-1b624xu {
    /* toggle circle checked */
  background-color: #2B2B26;
  border: 1px solid #2B2B26;
}

.low-law {
  color: #FFFCEB; /*mandatory*/
  text-decoration: none; /*mandatory*/
  padding-inline: 5px;
}

.low-law-h6 {
  font-weight: 300 !important;
}

.footer-text-hover:hover {
  color: #FCE868;
}

.codeBlock:focus-visible{
  outline: #D3C3A6 solid 2px
}

/* user access message tooltip*/
.MuiTooltip-tooltip {
  font-family: "Work sans" !important;
  padding: 7px 8px !important;
  font-size: 14px !important;
  max-width: 300px !important;
  text-align: right;
  margin-right: 0px !important;
  /* word-wrap: break-word; */
}

.introjs-tooltip {
  min-width: 220px !important;
  right: 20px !important;
  /* background-color: #f8f2cd !important; */
  font-family: "Work sans" !important;
}

.introjs-tooltipReferenceLayer * {
  font-family: "Work sans",Inter,ui-sans-serif,"Apple Color Emoji",Helvetica,Arial,sans-serif !important;
}

.introjs-tooltiptext {
  padding: 5px 15px !important;
  font-size: 17px !important;
  line-height: 22px !important;

}

.introjs-progress {
  background-color: #efefef !important;
  /* border: 0.5px solid #2B2C26; */
}

.introjs-progressbar {
  background-color: #D3C3A6 !important;
}

.introjs-helperNumberLayer {
  color: #2B2C26 !important;
  padding: 0px 0px 10px 0px !important;
  font-size: 15px !important;
  line-height: 15px !important;
}

.introjs-button {
  padding: .4rem 0.6rem !important;
  border: 0.5px solid #D3C3A6 !important;
  text-decoration: none;
  text-shadow: none !important;
  font-size: 14px;
  color: #F98050 !important;
  background-color: #FFF !important;
  border-radius: 8px !important;
}

/* IUBENDA */

/* #iubenda-cs-banner .iub-toggle-checkbox input.style1:checked, #iubenda-iframe .iub-toggle-checkbox input.style1:checked {
  background-color: #2B2B26 !important;
}
 
#iubenda-iframe #iubFooterBtnContainer button {
  background-color: #F98050!important;
  color: #fffdef!important;
}

#iubenda-iframe [tabindex]:not([tabindex="-1"]):focus,
#iubenda-iframe a[href]:focus, 
#iubenda-iframe button:focus, 
#iubenda-iframe details:focus, 
#iubenda-iframe input:focus, 
#iubenda-iframe select:focus, 
#iubenda-iframe textarea:focus,
#iubenda-cs-banner [tabindex]:not([tabindex="-1"]):focus,
#iubenda-cs-banner a[href]:focus,
#iubenda-cs-banner button:focus, 
#iubenda-cs-banner details:focus, 
#iubenda-cs-banner input:focus, 
#iubenda-cs-banner select:focus,
#iubenda-cs-banner textarea:focus {
  outline-color: #F98050!important;
} */
