  .accordion-beije {
    --bs-accordion-color: #2C2B26 !important;
    --bs-accordion-bg: #d3c3a618 !important;
    --bs-accordion-btn-bg: #d3c3a65e  !important;
    --bs-accordion-border-color: #FFFCEB !important;
    --bs-accordion-active-bg: #FFFCEB  !important;
    --bs-accordion-active-color: #F98050 !important;
    /* --bs-accordion-btn-color:  !important; */
    --bs-accordion-btn-focus-box-shadow: none !important;
    /* ------color---------------------------------------------------------------------------------------------------------------------------oooooo--------------------------... */
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23F98050%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    /* --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2381C3bf%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important; */
    --bs-accordion-inner-border-radius: 20px !important;
    --bs-accordion-border-radius: 20px !important;
  }

  .accordion-yellow {
    --bs-accordion-color: #2C2B26 !important;
    --bs-accordion-bg: #FFFDEF !important;
    --bs-accordion-btn-bg: #fffbe5  !important;
    --bs-accordion-border-color: #D3C3A6 !important;
    --bs-accordion-active-bg: #fffbe5  !important;
    --bs-accordion-active-color: #F98050 !important;
    /* --bs-accordion-btn-color:  !important; */
    --bs-accordion-btn-focus-box-shadow: none !important;
    /* ------color---------------------------------------------------------------------------------------------------------------------------oooooo--------------------------... */
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23F98050%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23D3C3A6%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    --bs-accordion-inner-border-radius: 20px !important;
    --bs-accordion-border-radius: 20px !important;
  }

  .accordion-item:hover .accordion-header-beije {
    /* color: #81C6BF ; */
    --bs-accordion-btn-bg: #d3c3a618 !important;
    /* --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2381C3BF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important; */
  }

  .accordion-item:hover .accordion-header-yellow {
    /* color: #81C6BF ; */
    --bs-accordion-btn-bg: #fff8d4 !important;
    /* --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2381C3BF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important; */
  }