:root {
  --swiper-theme-color: #2C2B26;
}

.swiper {
  overflow-y: visible;
}

.swiper-pagination-bullet {
  width: 29px;
  height: 29px;
  display: inline-block;
  border-radius: 50%;
  background: #FFFDEF;
  opacity: 1;
  bottom: 0px;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color,var(--swiper-theme-color));
}

.feedback-swiper.swiper-button-prev,
.feedback-swiper.swiper-button-next {
  color: #FFFDEF !important;
  font-size: 24px;
  width: 65px !important;
  top: calc(50%) !important;
  height: 65px;
  background-color: transparent;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  stroke: #2B2B26 !important;
  stroke-width: 7px !important;
}

.feedback-swiper.swiper-button-prev {
  left: 30px !important;
}

.feedback-swiper.feedback-swiper.swiper-button-next {
  right: 30px !important;
}

.feedback-swiper.swiper-button-disabled {
  display: none !important;
}

.feedback-swiper.swiper-button-prev:hover,
.feedback-swiper.swiper-button-next:hover {
    width: 80px !important;
    height: 80px !important;
    top: calc(50% - 7.5px) !important;
}

.feedback-swiper.swiper-button-prev:hover {
  left: 15px !important;
}

.feedback-swiper.swiper-button-next:hover {
  right: 15px !important;
}
